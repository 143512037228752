import HOST from './host';

const SCHEMA = `${ HOST }/scheme`;
const SHARE = `${ HOST }/scheme/share`;

const API = {

  sign: {

    up: `${ HOST }/signup`,
    in: `${ HOST }/signin`,
    check: `${ HOST }/check`,
    disableDefaultOpenedHelp: `${ HOST }/help-showed`

  },

  tariff: {

    pay: `${ HOST }/tariff/pay`,

  },

  user: {

    setLang: `${ HOST }/user/setLang`,

  },

  scheme: {

    all: `${ SCHEMA }/all`,
    get: `${ SCHEMA }/get`,
    add: `${ SCHEMA }/add`,
    save: `${ SCHEMA }/save`,
    rename: `${ SCHEMA }/rename`,
    remove: `${ SCHEMA }/remove`,

    dumpSave: `${ SCHEMA }/dumpSave`,

    share: {

      changeMode:   `${ SHARE }/changeMode`,
      addEmail:     `${ SHARE }/addEmail`,
      changeAccess: `${ SHARE }/changeAccess`,
      removeEmail:  `${ SHARE }/removeEmail`,

    }

  },

}

export default API;