import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import Container from "../../components/container";
import Button from "../../components/button";
import { ReactComponent as DB } from "./db.svg";
import { WORDBOOK } from "../../../locales";
import './payment-result.scss';

const PaymentResult = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_tariff, locale } = globalState;
  const navigate = useNavigate();
  const [ isSuccess, setIsSuccess ] = useState( "loading" );

  useEffect(() => {

    if ( window.location.search.indexOf('success') !== -1 ) {
      setIsSuccess( true );
    } else  if ( window.location.search.indexOf('fail') !== -1 ) {
      setIsSuccess( false );
      //TODO запрос на смену статуса платежа canceled
    
    } else {
      window.location = "/";
    }

  }, [ ]);

  const t = WORDBOOK[ locale ].payment;

  return (

    <Container>

      <div className="sign">

        { isSuccess === "loading" ? <div className="sign__content payment-result"></div>
          
          : isSuccess 
          
          ? <div className="sign__content payment-result">

              <p className="payment-result__title">{ t.title }</p>
              <p className="payment-result__subtitle">{ t.subtitle } { user_tariff?.next_date }</p>

              <DB />

              <p className="payment-result__thanking">{ t.thanking }</p>

              <Button action = {() => { window.location = '/' }}>{ t.btnEnter }</Button>

            </div>
          
          : <div className="sign__content payment-result payment-result--fail">

              <p 
                className="payment-result__title" 
                style={{
                  paddingBottom: '0',
                  marginBottom: '1.8vw'
                }}
              >
                { t.cancelPayment }
              </p>

              <p className="payment-result__thanking" style={{marginTop: '0'}}>{ t.cancelPaymentThanking }</p>
              <p className="payment-result__subtitle">{ t.cancelPaymentSubtitle }</p>

              <DB />

              <p className="payment-result__thanking">{ t.cancelPaymentRepeat }</p>

              <Button
                    
                action = {() => { navigate('/tariff'); }}
                children = { t.return }

              />

            </div>
            
          }

      </div>

    </Container>

  );

}


export default PaymentResult;