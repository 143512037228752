import React, { useEffect }  from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/container";
import Button from "../../components/button";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";
import cookie from "react-cookies";

import { ReactComponent as Check } from "./icons/add-table-col.svg";
import { TARIFF } from "../../../settings/constants";

import './tariff.scss';
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";

const Tariff = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { locale } = globalState;
  const { sign } = globalActions;
  const navigate = useNavigate();
	const token = cookie.load('token');

  const [ Dummy, reloadDummy ] = useReloadDummy();

	useEffect(() => { 

		if ( !token ) { sign.Out(); }
      
    sign.Check( token, false, navigate );
  
  }, []);

  useEffect(() => { reloadDummy() }, [ locale ])

  const t = WORDBOOK[ locale ].tariff;

  return (

    <Container>

      <Dummy />

      <div className = "sign">

        <div className = "sign__content tariff">

          <div>

            <p className = "tariff__title">{ t.p1 }</p>
            <p className = "tariff__second_title">{ t.p2 }</p>

            <div className = "tariff__list__container">

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p3 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p4 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p5 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p6 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p7 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p8 }</p>

              </div>

              <div className = "tariff__list__container__content">

                <Check />
                <p>{ t.p9 }</p>

              </div>

            </div>

            <div className = "tariff__respect flex justify-center items-center">

              <div className = "tariff__respect__icon tariff__respect__icon--ava" />
              { t.p10 }
              <div className = "tariff__respect__icon tariff__respect__icon--smile" />
              <br className = "mobile" />
              { t.p11 }
              <div className = "tariff__respect__icon tariff__respect__icon--money" />

            </div>

            <div className = "tariff__buttons__container">

              <div className = "tariff__buttons__container__content tariff__buttons__container__content_left">

                <p className = "tariff__buttons__container__content__text tariff__buttons__container__content__text-left">{ t.priceMonth }</p>
                <p className = "tariff__buttons__container__content__subtext"><span>{ t.priceDayTariffMonth }</span></p>
                
                <Button action = {() => { globalActions.tariff.pay( TARIFF.MONTH ) }}>{ t.payMonth }</Button>

              </div>

              <div className = "tariff__buttons__container__content tariff__buttons__container__content_right">

                <p className = "tariff__buttons__container__content__text tariff__buttons__container__content__text-right">{ t.priceYear }</p>
                <p className = "tariff__buttons__container__content__subtext">🔥 <span>{ t.priceDayTariffYear }</span> 🔥</p>

                <Button action = {() => { globalActions.tariff.pay( TARIFF.YEAR ) }}>{ t.payYear }</Button>

              </div>

            </div>

            <p className = "tariff__subtitle">{ t.p12 }</p>

          </div>
            
        </div>

      </div>

    </Container>

  );

}

export default Tariff;