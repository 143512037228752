import { WORDBOOK } from "../locales";

const FOREIGN_ON_ACTIONS = ( locale ) => [
  {
    id: 1,
    name: `CASCADE`,
    tip: WORDBOOK[ locale ].foreignOnActions.tip1,
  },
  {
    id: 2,
    name: `SET NULL`,
    tip: WORDBOOK[ locale ].foreignOnActions.tip2,
  },
  {
    id: 3,
    name: `RESTRICT`,
    tip: WORDBOOK[ locale ].foreignOnActions.tip3,
  },
  {
    id: 4,
    name: `SET DEFAULT`,
    tip: WORDBOOK[ locale ].foreignOnActions.tip4,
  },
];

export {
  FOREIGN_ON_ACTIONS
}