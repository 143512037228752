import { useState } from "react";
import { ButtonBlockRemove as Remove } from "../../components/button.block.remove";
import cssIf from "../../../scripts/helpers/class.add.if";
import Textfield from "../textfield";
import Button from "../button";
import Selectfield from "../selectfield";
import { SHARE_ACCESS } from "../../../settings/constants";
import { WORDBOOK} from "../../../locales";
import useGlobal from "../../../store";
import s from "./share.email.row.module.scss";
import "./share.email.row.scss";

const ShareEmailRow = ( props ) => {

  const {
  
    id = null,
    email = "",
    access = SHARE_ACCESS.READ,
    action = () => {},
    changeAccessAction = () => {}

  } = props;

  const [ globalState ] = useGlobal();

  const wordbook = WORDBOOK[ globalState.locale ];
  const t = wordbook.shareEmailRow;

  const SHARE_ACCESS_LIST = [ 
    
    {
      id: SHARE_ACCESS.READ,
      name: wordbook.constants.SHARE_ACCESS_LIST.reader,
    }, 
    
    {
      id: SHARE_ACCESS.EDIT,
      name: wordbook.constants.SHARE_ACCESS_LIST.editor,
      disabled: true
    }

  ]

  const add = id === null;

  const [ mail, setMail ] = useState( email );
  const [ _access, setAccess ] = useState( SHARE_ACCESS_LIST.find( el => el.id === access ) );

  function handleChangeAccess( a ) {

    setAccess( a );

    changeAccessAction({

      share_id: id,
      access: a.id 
    
    });

  }

  function handleAction() {

    let form = {

      email: mail,
      access: _access.id

    }

    if ( id ) { form.share_id = id; }

    action( form );

    if ( add ) { setMail(""); }

  }

  return (

    <div className = {`share_email_row ${ s.container } ${ cssIf( add, s.container_add ) } flex items-bottom`}>

      <Textfield

        title = { add && t.title }
        value = { mail } 
        set = { ( m ) => { add && setMail( m ) }} 
        placeholder = "example@mail.com"
        inputClassName = { cssIf( !add, `${ s.email } events-none select-none`) }

      />

      <Selectfield

        selected = { _access }
        select = { handleChangeAccess }
        list = { SHARE_ACCESS_LIST }
        className = { s.selectfield } 

      />

      <Button
        className = {`${ s.button } ${ cssIf( !add, s.button_remove ) } relative`} 
        action = { handleAction }
      >
        { add ? "+" : <Remove className = {`${ s.remove } events-none`} /> }
      </Button>


    </div>

  );

}

export default ShareEmailRow;