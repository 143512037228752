const THEME = {

  DARK: 1,
  LIGHT: 2

}

const DOCUMENTS = {

  offer: `https://database-design.ru/offer/`,
  privacy: `https://database-design.ru/privacy/`

}

const TARIFF = {

  MONTH: 1,
  YEAR: 2

}

const TARIFF_PRICE = {

  MONTH: { RUB: 777, DOLLAR: 9 },
  YEAR: { RUB: 5555, DOLLAR: 64 },

}


const SHARE = {

  ONLY_ME: 1,
  PUBLIC:  2,
  EMAILS:  3

}

const SHARE_ACCESS = {

  READ: 1,
  EDIT: 2

}

export {
  
  THEME,
  DOCUMENTS,
  TARIFF,
  TARIFF_PRICE,
  SHARE, 
  SHARE_ACCESS,

}