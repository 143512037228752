import { ru } from "./ru"
import { eng } from "./eng"
import { zh } from "./zh"

const LOCALE = {

  RU: 'ru',
  ENG: 'eng',
  CHINA: 'zh',

}

const WORDBOOK = {

  [ LOCALE.RU ]: ru,
  [ LOCALE.ENG ]: eng,
  [ LOCALE.CHINA ]: zh,

}

export {

  LOCALE,
  WORDBOOK

}

//const router = useRouter();
//   const t = WORDBOOK[ router.locale ].articleContentFooter;