import React, { useState, useEffect, Fragment, useRef } from "react";
import cookie from 'react-cookies';
import { useNavigate } from "react-router-dom";
import './sign.scss';

import Textfield from "../../components/textfield";
import Button, { ButtonRouter } from "../../components/button";
import Container from "../../components/container";
import useGlobal from "../../../store";
import { validateEmail } from "../../../scripts/helpers/validators";
import Checkbox from "../../components/checkbox";
import { DOCUMENTS } from "../../../settings/constants";
import VerifyCodeField from "../../components/verify.code.field";
import { WORDBOOK } from "../../../locales";

const Up = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;
  const navigate = useNavigate();
  const redirect = () => navigate('/');

  const [ email, setEmail ] = useState ("");
  const [ phone, setPhone ] = useState ("");
  const [ password, setPassword ] = useState ("");
  const [ repeat, setRepeat ] = useState ("");  
  const [ offerAccepted, setOfferAccepted ] = useState( false );
  const [ privacyAccepted, setPrivacyAccepted ] = useState( false );
  const [ error, setError ] = useState ("");
  const [ isCodeSended, setIsCodeSended ] = useState( false );

  const emailField    = useRef( null );
  const phoneField    = useRef( null );
  const passwordField = useRef( null );
  const repeatField   = useRef( null );

  const wordbook = WORDBOOK[ globalState.locale ];
  const t = wordbook.signup;

  const isFormValidated = validateEmail( email ) && password !== "" && password === repeat && offerAccepted && privacyAccepted;

  useEffect( () => { setError(""); }, [ email, password, repeat ]);

  useEffect( () => {

    const passRepeatError = ( 

      validateEmail( email ) 
      && 
      password !== "" 
      && 
      password !== repeat 
      && 
      offerAccepted 
      && 
      privacyAccepted 

    );
    
    setError( passRepeatError ? t.bad_pass : "" );

  }, [

    email,
    password,
    repeat,
    offerAccepted,
    privacyAccepted

  ]);

  const getUtmFromCookies = () => {

    const utm_campaign = cookie.load('utm_campaign');
    const utm_source = cookie.load('utm_source');
    const utm_firstpage_url = cookie.load('utm_firstpage_url');
    const utm_firstscreen_width = cookie.load('utm_firstscreen_width');
    const utm_firstscreen_height = cookie.load('utm_firstscreen_height');

    return {

      utm_campaign,
      utm_source,
      utm_firstpage_url,
      utm_firstscreen_width,
      utm_firstscreen_height,

    }

  }

  const handleSendCode = () => { 
    
    error && setError( false );

    sign.Up({ 
      
      email, 
      phone, 
      password, 
      repeat, 
      onSuccess: () => setIsCodeSended( true ), 
      onError: setError,
      ...getUtmFromCookies() 
    
    })
  
  };
  
  const handleVerifyCode = code => {
    
    error && setError( false );

    sign.Up({ 

      email, 
      phone, 
      password, 
      repeat, 
      code, 
      onSuccess: redirect, 
      onError: setError,
      ...getUtmFromCookies(),
  
    });
  
  }


  return (

    <Container>

      <div className = "sign sign--up">

        <div className = "sign__content sign__content-reg">

          { !isCodeSended

            ? <div>

              <Textfield

                title = { t.email }
                type = "email"
                value = { email }
                set = { value => setEmail( value.replace(/[а-яА-ЯёЁ\s]/g, "" ) ) }
                refDOM = { emailField }
                placeholder = { t.email }
                onKeyUp = { e => e.key === 'Enter' && phoneField.current.focus() }

              />

              <Textfield

                title = { t.phone }
                type = "tel"
                value = { phone }
                set = { setPhone }
                refDOM = { phoneField }
                placeholder = "+7 999 999-99-99"
                onKeyUp = { e => e.key === 'Enter' && passwordField.current.focus() }

              />

              <Textfield

                title = { t.password }
                type = "password"
                value = { password }
                set = { setPassword }
                refDOM = { passwordField }
                placeholder = { t.passwordPlaceholder }
                onKeyUp = { e => e.key === 'Enter' && repeatField.current.focus() }

              />

              <Textfield

                title = { t.passwordRepeat }
                type = "password"
                value = { repeat }
                set = { setRepeat }
                refDOM = { repeatField }
                placeholder = { t.passwordRepeatPlaceholder }
                onKeyUp = { e => ( e.key === 'Enter' && isFormValidated ) && handleSendCode() }

              />
              
              <hr />

              { error !== "" && <Fragment><p className="error">{ error }</p><hr /></Fragment> }

              <Checkbox

                title = {

                  <Fragment>
                    { t.offerSTART } <a href = { DOCUMENTS.offer } target = "_blank" rel = "norefferer" onClick = {( e ) => { e.stopPropagation(); }}>
                    { t.offer1 }
                  </a>
                  </Fragment>
                  
                }
                value = { offerAccepted }
                set = { setOfferAccepted }

              />

              <Checkbox

                title = {
                  <Fragment>
                    { t.offerSTART } <a href = { DOCUMENTS.privacy } target = "_blank" rel = "norefferer" onClick = {( e ) => { e.stopPropagation(); }}>{ t.offer2 }</a>, { t.offer2 }
                  </Fragment>
                }
                value = { privacyAccepted }
                set = { setPrivacyAccepted }

              />

              <Button
          
                action = { handleSendCode }
                disabled = { !isFormValidated }

              >
                { t.signup }

              </Button>

              <p className="text-center pointer"
                 onClick = { () => { navigate('/signin') } }
                >{ t.hasAccount }</p>

              <ButtonRouter url = "/signin">{ t.auth }</ButtonRouter>
          
              </div>

          : <VerifyCodeField

              wordbook = { wordbook }
              resendCode = { handleSendCode }
              verifyCode = { handleVerifyCode }
              setError = { setError }
              error = { error }

            />

          }

        </div>

      </div>

    </Container>

  );

}

export default Up;