import { TrashIcon } from "./icons";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";
import s from "./button.block.remove.module.scss";

//? imported as "Remove"
const ButtonBlockRemove = ( props ) => {

  const [ globalState, globalActions ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].buttonBlockRemove;

  const { action = false, tip = t.del, className = "" } = props;
  
  return (

    <button

      title = { tip }
      className = {`${ s.button } ${ className } pointer`}
      onClick = { ( e ) => { action && action(); e.stopPropagation(); } }
    
    >

      <TrashIcon />

    </button>

  );

}

export { ButtonBlockRemove };