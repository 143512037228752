import s from "./side.panel.tools.module.scss";
import { ReactComponent as GroupsIcon } from './icons/groups.svg';
import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { useState } from "react";
import ToolGroups from "./groups";
import useGlobal from "../../../store";
import cssIf from "../../../scripts/helpers/class.add.if";
import { THEME } from "../../../settings/constants";

const SidePanelTools = ({ className }) => {

  const WINDOW = {

    GROUPS: 1,
    TABLE: 2,
    TEXT: 3

  }

  const [ globalState, globalActions ] = useGlobal();
  const { theme } = globalState;

  const [ isShown, setIsShown ] = useState( false );
  const [ windowNumber, setWindowNumber ] = useState( null );

  const COLOR_GROUP_CONTROLLER = {

    [ WINDOW.GROUPS ]: (

      <ToolGroups theme = { theme } />

    ),

    [ WINDOW.TABLE ]: (

      <div/>

    ),

    [ WINDOW.TEXT ]: (

      <div/>

    ),

  }

  return (

    <div className = { s.wrapper }>

      <div className = {`${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `} >

        <GroupsIcon 
        
          title = "Показать группы таблиц"
          className = {`${ s.icon } ${ s.groups }`} 
          onClick = { () => { 
            setIsShown( !isShown ); 
            setWindowNumber( WINDOW.GROUPS );
          } }
        
        /> 

        {/* <div className = { s.line }/>

        <TableIcon 
        
          className = {`${ s.icon } ${ s.table }`} 
          onClick = { () => {
            setIsShown( !isShown ); 
            setWindowNumber( WINDOW.TABLE ); 
          } }
        
        /> 

        <div className = { s.line }/>

        <TextIcon 
        
          className = {`${ s.icon } ${ s.text }`} 
          onClick = { () => { 
            setIsShown( !isShown ); 
            setWindowNumber( WINDOW.TEXT ); 
          } }
        
        />  */}

        <div className = {` ${ s.tool } ${ cssIf( isShown, s.shown ) } `}>

          { COLOR_GROUP_CONTROLLER[ windowNumber ] }

        </div>

      </div>

    </div>

  );

}

export default SidePanelTools;