import { useEffect, useState } from "react";
import { ReactComponent as Mouse } from "./icons/mouse.svg";
import { ReactComponent as Touchpad } from "./icons/touchpad.svg";
import { CURSOR } from "../../../settings/user.mouse.settings";
import cssIf from "../../../scripts/helpers/class.add.if";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";

const ToggleMouse = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_controller, locale } = globalState;
  const { changeStates } = globalActions;

  const t = WORDBOOK[ locale ].toggleMouse;
  
  const action = v => {
    
    localStorage.setItem('mouse', v )
    changeStates({ user_controller: v })
  
  }

  const elementWidth = 100 / 2;
  const [ anchorOffset, setAnchorOffset ] = useState( 0 );

  useEffect(() => { setAnchorOffset( ( 100 / 2 ) * ( user_controller - 1 )); }, [ user_controller ]);
  
  return (

    <div className = "toggletwo togglemouse">

      <div className = {`toggletwo__container relative`}>

        <div className = {`toggletwo__list absolute flex`}>

          <div
            onClick = { () => { action( CURSOR.MOUSE ) } }
            style = {{ width: `${ elementWidth }%` }}
            className={`toggletwo__list__element toggletwo__list__element--dark ${ cssIf( user_controller === CURSOR.MOUSE, `toggletwo__list__element--selected` ) } flex justify-center items-center`}
            title = { t.mouse }
          >
            <Mouse />
          </div>

          <div
            onClick = { () => { action( CURSOR.TOUCHPAD ) } }
            style = {{ width: `${ elementWidth }%` }}
            className={`toggletwo__list__element toggletwo__list__element--light ${ cssIf( user_controller === CURSOR.TOUCHPAD, `toggletwo__list__element--selected` ) } flex justify-center items-center`}
            title = { t.touchpad }
          >
            <Touchpad />
          </div>

        </div>

        <div

          className = {`toggletwo__anchor absolute`}
          style = {{

            left: `${ anchorOffset }%`,
            width: `${ elementWidth }%`

          }}

        />
        
      </div>
     
    </div>

  );

}

export default ToggleMouse;