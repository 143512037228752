import cssIf from "../../../../../scripts/helpers/class.add.if";
import { THEME } from "../../../../../settings/constants";
import Textfield from "../../../textfield";
import s from "./description.module.scss";

const Description = ({ wordbook, description, setDescription, theme }) => {

  const t = wordbook;

  return (

    <div className = {` ${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `}>

      <Textfield

        title = { t.description }
        value = { description }
        set = { setDescription }
        placeholder = { t.placeholder }

      />

    </div>

  );

}

export default Description;