import * as popup from './popup';
import * as sign from './sign';
import * as user from './user';
import * as tariff from './tariff';
import * as share from './share';
import * as scheme from './scheme';
import * as tableColorGroup from './table.color.group';
import * as exportDump from './export';
import { RandomHash } from '../../scripts/hooks/use.reload.dummy';

async function changeStates( store, states ) {

  await store.setState( states );
  return true;

}

async function changeObjectState( store, object, state, value ) {

  store.setState({

    [ object ]: {

      ...store.state[ object ],
      [ state ]: value

    }

  });

}

async function updateRender( store ) {

  store.setState({ dummy: RandomHash() })

} 

export {

  changeStates,
  changeObjectState,
  updateRender,
  popup,
  sign,
  user,
  tariff,
  scheme,
  share,
  exportDump,
  tableColorGroup
  
}