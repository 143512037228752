import { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { ReactComponent as Close } from './media/close.svg';
import { ReactComponent as Arrow } from './media/arrow.svg';
import { minutesToMiliseconds } from '../../../scripts/helpers/time';
import { WORDBOOK } from "../../../locales";
import useGlobal from '../../../store';
import "./message.from.rikky.scss";

const MessageFromRikky = ( props ) => {

  const [ globalState ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].messageFromRikky;

  const {

    title,
    text,
    subtitle

  } = props;

  const prevent_show_msg_version = 'prevent-show-msg-211123';

  const [ isHidden, setIsHidden ] = useState( true );

  const hide = {

    show_later: () => { 

      const expirationTime = new Date();
      expirationTime.setTime( expirationTime.getTime() + minutesToMiliseconds( 25 ) ); 
      cookie.save( 'wait_for_show_msg_from_admin', true, { path: '/', expires: expirationTime } ); 
      setIsHidden( true );
    
    },

    forever: () => {

      cookie.save( prevent_show_msg_version, true, { path: '/' } ); 
      setIsHidden( true );

    }

  }

  function controlVisible() {

    const waiting = cookie.load('wait_for_show_msg_from_admin');
    const prevent_show = cookie.load( prevent_show_msg_version );

    // console.log(`controlVisible() waiting = ${ waiting ? "true" : "false" }, prevent_show = ${ prevent_show ? "true" : "false" }, `);

    setIsHidden( waiting || prevent_show );

  }

  useEffect(() => {

    const prevent_show = cookie.load( prevent_show_msg_version );
    
    const checkingVisible = setInterval( controlVisible, minutesToMiliseconds( 5 ) );
    
    if ( prevent_show ) { 
      
      clearInterval( checkingVisible );
      return;
    
    }    

    return () => {

      clearInterval( checkingVisible );

    }

  }, []);

  return ( isHidden ? null :

    <div className = "message_from_rikky tariff__respect fixed">

      <div className = "tariff__respect flex justify-center items-center">

        <div className = "tariff__respect__icon tariff__respect__icon--ava message_from_rikky__image" />

        <div className = "message_from_rikky__content">

          <div className="message_from_rikky__content__title">

            { title }

          </div>

          <div className="message_from_rikky__content__text">

            { text }

          </div>

          <div className="message_from_rikky__content__subtitle flex">
          
            <Arrow /> { subtitle }

          </div>

        </div>

        <Close className = "message_from_rikky__close absolute pointer" />

        <div className="close_variants absolute">

          <div onClick = { hide.show_later } className="close_variants__var close_variants__var--1 absolute">{ t.close1 }</div>
          <div onClick = { hide.forever } className="close_variants__var close_variants__var--2 absolute">{ t.close2 }</div>

          <div className="close_variants__line close_variants__line--1 absolute" />
          <div className="close_variants__line close_variants__line--2 absolute" />


        </div>

      </div>

    </div>

  );

}

export default MessageFromRikky;