import { Fragment, useEffect } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import Table from "../table";
// import { useControls } from "react-zoom-pan-pinch";


const RenderTables = ( props ) => {

  const [ Dummy, reloadDummy ] = useReloadDummy();
  // const { centerView, zoomToElement } = useControls();

  const {

    tables = [],
    canEdit = true,
    areaScale,
    renameTable,
    duplicateTable,
    removeTable,
    assignColorGroup,
    addColumn,
    editColumn,
    removeColumn,
    updateTablePosition,
    updateRowsPosition,
    setDisablePinchZoom,
    setTablesOnTopLayer

  } = props;

  useEffect(() => { reloadDummy(); }, [ tables ])

  // useEffect(() => {

  //   if ( !tables?.length ) return;

  //   const tID = `table-${tables[ tables.length - 1 ].id}`;
  //   const t = document.getElementById( tID );
  //   console.log(`zoomToElement(${ tID })`,t);
  //   zoomToElement( t );

  // }, [ tables?.length ]);

  return (

    <Fragment>

      <Dummy />

      { tables.map(( table, key ) => (

        <Table

          key = { key }
          id = { table.id }
          name = { table.name }
          tip = { table.tip }
          groupID = { table?.groupID }
          assignColorGroup = { assignColorGroup }
          color = { table.color }
          canEdit = { canEdit }
          areaScale = { areaScale }
          columns = { table.columns }
          tablesLength = { tables.length }
          position = { table.position }
          renameTable = { renameTable }
          duplicateTable = { () => duplicateTable( table ) }
          removeTable = { removeTable }
          addColumn = { addColumn }
          editColumn = { editColumn }
          removeColumn = { removeColumn }
          updateTablePosition = { updateTablePosition }
          updateRowsPosition = { updateRowsPosition }
          setDisablePinchZoom = { setDisablePinchZoom }
          setTablesOnTopLayer = { setTablesOnTopLayer }

        />

      ))} 

    </Fragment>

  );

}

export default RenderTables;