import { WORDBOOK } from "../locales";

const DT_GROUP = {

  NUMBER:   1,
  DECIMAL:  2,
  TEXT:     3,
  BINARY:   4,
  DATETIME: 5,
  CUSTOM:   6,
  SPATIAL:  7,
  OTHER:    8

}

const DTG = DT_GROUP;

const DATA_TYPES = [

  { id:   0, group: DTG.NUMBER,   name: "integer" },
  { id:  15, group: DTG.NUMBER,   name: "tinyint" },
  { id:  16, group: DTG.NUMBER,   name: "smallint" },
  { id:  17, group: DTG.NUMBER,   name: "mediumint" },
  { id:  18, group: DTG.NUMBER,   name: "bigint" },
  
  { id:  10, group: DTG.DECIMAL,  name: "float" },
  { id:  19, group: DTG.DECIMAL,  name: "double" },
  { id:  20, group: DTG.DECIMAL,  name: "decimal" },

  { id:   1, group: DTG.TEXT,     name: "varchar" },
  { id:   2, group: DTG.TEXT,     name: "text" },
  { id:  21, group: DTG.TEXT,     name: "char" },
  { id:   6, group: DTG.TEXT,     name: "tinytext" },
  { id:   7, group: DTG.TEXT,     name: "mediumtext" },
  { id:   8, group: DTG.TEXT,     name: "longtext" },

  { id:  28, group: DTG.BINARY,   name: "binary" },
  { id:  29, group: DTG.BINARY,   name: "varbinary" },
  { id:  12, group: DTG.BINARY,   name: "blob" },
  { id:  11, group: DTG.BINARY,   name: "tinyblob" },
  { id:  13, group: DTG.BINARY,   name: "mediumblob" },
  { id:  14, group: DTG.BINARY,   name: "longblob" },

  { id:  22, group: DTG.DATETIME, name: "date" },
  { id:   3, group: DTG.DATETIME, name: "datetime" },
  { id:   4, group: DTG.DATETIME, name: "timestamp" },
  { id:  23, group: DTG.DATETIME, name: "time" },
  { id:  24, group: DTG.DATETIME, name: "year" },

  { id:  25, group: DTG.CUSTOM,   name: "enum" },
  { id:  26, group: DTG.CUSTOM,   name: "set" },

  { id:  30, group: DTG.SPATIAL,  name: "geometry" },
  { id:  31, group: DTG.SPATIAL,  name: "point" },
  { id:  32, group: DTG.SPATIAL,  name: "linestring" },
  { id:  33, group: DTG.SPATIAL,  name: "polygon" },
  { id:  35, group: DTG.SPATIAL,  name: "multipoint" },
  { id:  37, group: DTG.SPATIAL,  name: "multipolygon" },
  { id:  36, group: DTG.SPATIAL,  name: "multilinestring" },
  { id:  34, group: DTG.SPATIAL,  name: "geometrycollection" },

  { id:   5, group: DTG.OTHER,    name: "boolean" },
  { id:   9, group: DTG.OTHER,    name: "json" },
  { id:  27, group: DTG.OTHER,    name: "uuid" },

];

const t = WORDBOOK;

const DATA_TYPES_TIPS = ( locale ) => [

  /* integer            */ { id:   0, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i0 },
  /* tinyint            */ { id:  15, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i15 },
  /* smallint           */ { id:  16, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i16 },
  /* mediumint          */ { id:  17, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i17 },
  /* bigint             */ { id:  18, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i18 },
  
  /* float              */ { id:  10, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i10 },
  /* double             */ { id:  19, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i19 },
  /* decimal            */ { id:  20, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i20 },

  /* varchar            */ { id:   1, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i1 },
  /* text               */ { id:   2, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i2 },
  /* char               */ { id:  21, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i21 },
  /* tinytext           */ { id:   6, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i6 },
  /* mediumtext         */ { id:   7, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i7 },
  /* longtext           */ { id:   8, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i8 },
  
  /* binary             */ { id:  28, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i28 },
  /* varbinary          */ { id:  29, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i29 },
  /* blob               */ { id:  12, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i12 },
  /* tinyblob           */ { id:  11, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i11 },
  /* mediumblob         */ { id:  13, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i13 },
  /* longblob           */ { id:  14, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i14 },

  /* date               */ { id:  22, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i22 },
  /* datetime           */ { id:   3, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i3 },
  /* timestamp          */ { id:   4, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i4 },
  /* time               */ { id:  23, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i23 },
  /* year               */ { id:  24, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i24 },

  /* enum               */ { id:  25, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i25 },
  /* set                */ { id:  26, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i26 },

  /* geometry           */ { id:  30, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i30 },
  /* point              */ { id:  31, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i31 },
  /* linestring         */ { id:  32, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i32 },
  /* polygon            */ { id:  33, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i33 },
  /* multipoint         */ { id:  35, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i35 },
  /* multipolygon       */ { id:  37, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i37 },
  /* multilinestring    */ { id:  36, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i36 },
  /* geometrycollection */ { id:  34, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i34 },

  /* boolean            */ { id:   5, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i5 },
  /* json               */ { id:   9, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i9 },
  /* uuid               */ { id:  27, tip: t[ locale ].dataTypes.DATA_TYPES_TIPS.i27 },
];

function assignTipsForTypes( withoutTipsTypes, locale ) {

  const result = []

  withoutTipsTypes.forEach( type => {
    
    let assigned = type;

    const finded = DATA_TYPES_TIPS( locale ).find( tip => +tip.id === +type.id );
    if ( finded ) { assigned.tip = finded.tip; }

    result.push( assigned );

  });

  return result;

}

export {

  DATA_TYPES,
  assignTipsForTypes,
  DT_GROUP

}