import React, { useState, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import { WORDBOOK } from "../../../locales";
import useGlobal from '../../../store';
import "./selectfield.scss";

const Selectfield = ( props ) => {

  const [ globalState ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].selectfield;

  const {

    selected = false, 
    select, 
    list = [], 
    isLoading = false, 
    title = "", 
    placeholder = t.placeholder,
    className = "",
    tip = "",
    setDisablePinchZoom = () => {}

  } = props;

  const LIST_MAX_HEIGHT = 500;

  const listContainer = useRef();
  const [ isOpened, setIsOpened ] = useState( false );

  function Toggle() {

    if ( !isOpened ) { listContainer.current.style.overflow = "hidden"; }
    
    setIsOpened( !isOpened );
    setDisablePinchZoom( !isOpened );

    let listContainerHeight = listContainer.current.scrollHeight + 20;

    if ( listContainerHeight > LIST_MAX_HEIGHT ) { 
      
      listContainerHeight = LIST_MAX_HEIGHT;
      listContainer.current.style.overflow = "auto";
    
    }
    
    listContainer.current.style.maxHeight = listContainer.current.style.maxHeight ? null : listContainerHeight + "px";

  }

  return (

    <div className = {`selectfield ${ cssIf( isOpened, "selectfield--opened" ) } ${ className }`}>

      { title !== "" && <div className = "selectfield__title">{ title }</div> }

      <div 
        className = {`selectfield__field ${ cssIf( !isLoading && !selected, "selectfield__field--placeholder" )} flex items-center`} 
        onClick = { Toggle }
        title = { tip?.length > 0 ? tip : undefined }
      >
        
        { isLoading ? t.loading : selected ? selected?.name ? selected?.name : selected : placeholder }

        {/* {( !isLoading && selected && object?.length > 0 ) &&  */}
        {( !isLoading && selected?.comment && selected?.comment?.length > 0 ) && 

          <div className="selectfield__field__object">

            - { selected.comment }

          </div> 
          
        }

        <div className="selectfield__field__arrow" />
        
      </div>

      <div className = "selectfield__list" ref = { listContainer }>

        { list.length > 0 && list.map(( item, key ) => (

          <div
            key = { key }
            title = { item?.tip || "" }
            className = {`selectfield__list__item ${ cssIf( item?.disabled, `disabled` ) } ${ cssIf( item?.hidden, `hidden` ) }`}
            onClick = { () => {

              !item?.disabled && select( item ); 
              Toggle(); 
              
            }}
          >

            { item?.name ? item.name : item }

          </div>

        ))}

      </div>

    </div>

  );

}

export default Selectfield;