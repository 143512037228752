import { WORDBOOK } from "../locales";

const INDEX_TYPES = ( locale ) => [
  {
    id: 1,
    name: `NORMAL`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_TYPES.NORMAL
  },
  {
    id: 2,
    name: `FULLTEXT`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_TYPES.FULLTEXT
  },
  {
    id: 3,
    name: `SPATIAL`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_TYPES.SPATIAL
  },
];

const INDEX_METHODS = ( locale ) => [
  {
    id: 1,
    name: `B-TREE`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_METHODS.BTREE
  },
  {
    id: 2,
    name: `HASH`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_METHODS.HASH
  },
  {
    id: 3,
    name: `GIN`,
    tip: WORDBOOK[ locale ].indexEnums.INDEX_METHODS.GIN
  },
];

export {
  
  INDEX_TYPES,
  INDEX_METHODS
  
}