import Textfield from ".";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";

const Searchfield = ( props ) => {

  const [ globalState ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].searchfield;

  return (

    <Textfield

      { ...props }
      className = {`searchfield ${ props?.className }`}
      placeholder = { t.text }
      icon = { <SearchIcon className = "searchIcon" /> }

    />

  );

}

export default Searchfield;