import cssIf from '../../../../../../scripts/helpers/class.add.if';
import { THEME } from '../../../../../../settings/constants';
import ColorRow from '../color.row';
import s from "./color.current.module.scss";

const ColorCurrent = ({ wordbook, color, name, onClick, theme }) => {

  const t = wordbook;

  return (

    <div className = {` ${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `}>

      <div className = { s.title }>

        <div className = { s.main }>{ t.tableGroup }</div>
        <div className = { s.help }>{ t.pushToChoose }</div>

      </div>

      <ColorRow 
      
        isChosen 
        color = { color } 
        name = { name || t.colorGroupNameEmpty } 
        onRowClick = { onClick }
      
      />

    </div>

  );

}

export default ColorCurrent;