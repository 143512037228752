import useGlobal from '../../../store';
import Xarrow from '../dynamic.arrows/xarrow';

const DynamicArrows = ({ data, table_id, table_group_id, zoom }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { tables, scheme_color_groups_visible } = globalState;

  const isThisTableColorGroupVisible = (

    !scheme_color_groups_visible.length
    ||
    scheme_color_groups_visible.includes( table_group_id )

  );

  function isForeignTableColorGroupVisible( foreign_table_id ) {

    const foreignTable = tables.find( el => el.id === foreign_table_id );
    if ( !foreign_table_id ) return false;
    const foreign_table_group_id = foreignTable.groupID;

    return (

      !scheme_color_groups_visible.length
      ||
      scheme_color_groups_visible.includes( foreign_table_group_id )
  
    )

  }
  
  return ( data.length > 0 && 

    <div className="dynamicArrows">
            
      { data.map(( col, index ) => (

        ( col?.foreign && isThisTableColorGroupVisible && isForeignTableColorGroupVisible( +col.foreign.split('-')[ 0 ] ) ) &&

        <Xarrow

          key = {index}
          start = { `row-${ table_id }-${ col?.id }` } //can be string
          end = { `row-${ col.foreign.split('-')[ 0 ] }-${ col.foreign.split('-')[ 1 ] }` } //can be ref
          color = { "#2FBCB4" } //TODO color from props
          startAnchor = {['left', 'right']}
          endAnchor = {['left', 'right']}
          strokeWidth = { 3 * zoom * 0.2 }
          animateDrawing = { +col.foreign.split('-')[ 0 ] !== +table_id }
          curveness = {0.8}
          isSemiCircleStyle = { +col.foreign.split('-')[ 0 ] === +table_id } // если таблица ссылается сама на себя

        />

      ))}

    </div>

  );

}

export default DynamicArrows;