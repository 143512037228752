async function add( store, color, name ) {

  const id = Date.now();
  const groups = store.state.scheme_color_groups || [];
  groups.push({ id, color, name });
  store.setState({ scheme_color_groups: groups });
  //updateRender?
  //schema.save?
  return id;

}

async function edit( store, { id, color, name }) {

  // console.log(`tableColorGroup.edit(${ id }, ${ color }, ${ name } )`);

  let groups = store.state.scheme_color_groups || [];
  let finded = groups.find( el => el.id === id );
  if ( !finded ) { return console.error(`tableColorGroup.edit error: !finded`); }

  finded.color = color;
  finded.name = name;

  store.setState({ scheme_color_groups: groups });
  //updateRender?
  //schema.save?

}

async function toggleView( store, groupID ) {

  const groups = store.state.scheme_color_groups || [];
  const allGroupIDs = groups.map( g => g.id );

  let visibleGroups = store.state.scheme_color_groups_visible || [];

  if ( groupID === 'all') {

    visibleGroups = [];

  } else if ( visibleGroups.includes( groupID ) ) {

    visibleGroups = visibleGroups.filter( g => g !== groupID );

  } else {
    
    visibleGroups.push( groupID );

  }

  store.setState({

    scheme_color_groups_visible: visibleGroups

  });

  // updateRender

}

export {

  add,
  edit,
  toggleView,

}