import axios from 'axios';
import cookie from 'react-cookies';
import API from '../../settings/api';

export async function all( store, reload = false ) {
  
  const token = cookie.load('token');
  if ( !token ) { console.error(`Попытка разлогина из all scheme`); return false; }

  const { status, data } = await axios.post( API.scheme.all, { token: token });
  
  if ( status === 200 && data?.schemes ) {
    
    store.setState({ 

      schemes: data.schemes,
      schemes_shared: data.schemes_shared,
      tables: []

    });
    
    reload && reload();

  } else {

    console.error( data.text );

  }

}

export async function get( store, id, reload ) {
  
  const token = cookie.load('token');

  const { status, data } = await axios.post( API.scheme.get, { token: token, id: id });
  
  if ( !data.success ) {

    alert( data.error );
    window.location = "/";
    return;

  }

  const tables = data.dataJSON !== null ? JSON.parse( data.dataJSON ) : [];

  const colorGroups = data.colorGroupsJSON 
    ? JSON.parse( data.colorGroupsJSON ) 
    : [{ id: 1, color: '#34A400', name: 'Без группы' }];

  // console.log(`data.dataJSON ${typeof data.dataJSON}`, data.dataJSON);
  console.log('tables', tables);
  
  await store.setState({

    scheme_id: id, 
    scheme_name: data.name, 
    scheme_description: data.description, 
    tables: tables, 
    scheme_access: data.scheme_access,
    scheme_shared_mode: data.share_mode,
    scheme_shared_emails: data.shared_emails,
    scheme_color_groups: colorGroups,

  });
  
  reload();

}

export async function save( store, id, dataJSON = false ) {

  try {

    const { scheme_color_groups } = store.state;
  
    const token = cookie.load('token');
    if ( !token ) { console.error(`Попытка разлогина из save scheme`); return false; }
  
    const params = {
  
      id: id,
      token: token, 
      dataJSON: dataJSON,
      colorGroupsJSON: scheme_color_groups
  
    }
  
    const { status, data } = await axios.post( API.scheme.save, params );
    
    if ( status !== 200 ) { throw( data?.text || data?.error ); }
  
    console.log('Таблицы успешно сохранены');
    return true;

  } catch ( err ) {

    console.error( err );
    alert(`Не удалось сохранить схему: ${ err }`);
    return false;
    
  }

}

export async function add( store, name, description ) {
  
  const token = cookie.load('token');
  if ( !token ) { console.error(`Попытка разлогина из add scheme`); return false; }

  const params = {

    token, 
    name,
    description

  }

  const { status, data } = await axios.post( API.scheme.add, params );
  
  if ( status === 200 ) {

    store.setState({

      schemes: [

        {

          id: data.id,
          name, 
          description

        },
        ...store.state.schemes,

      ]

    });

  } else {

    console.error( data.text );
    alert(`Не удалось добавить схему: ${ data?.text || data?.error }`);

  }

}

export async function rename( store, obj ) {
  
  const token = cookie.load('token');
  if ( !token ) { console.error(`Попытка разлогина из rename scheme`); return false; }

  const params = {

    id: store.state.scheme_id,
    token: token, 
    ...obj

  }

  const { status, data } = await axios.post( API.scheme.rename, params );
  
  if ( status === 200 ) {

    console.log('Проект успешно переименован');

  } else {

    console.error( data.text );
    alert(`Не удалось переименовать проект: ${ data?.text || data?.error }`);

  }

}

export async function remove( store, id, goBack ) {
  
  const token = cookie.load('token');
  if ( !token ) { console.error(`Попытка разлогина из remove scheme`); return false; }


  const params = {

    id: id,
    token: token

  }

  const { status, data } = await axios.post( API.scheme.remove, params );
  
  if ( status === 200 ) {

    goBack();
    store.actions.scheme.all();
    store.actions.scheme.resetProjectStates();

  } else {

    console.error( data.text );
    alert(`Не удалось удалить проект: ${ data?.text || data?.error }`);

  }

}

export async function resetProjectStates( store ) {
   
  store.setState({

    scheme_id: "",
    scheme_name: "",
    scheme_description: "",
    tables: []

  });

}