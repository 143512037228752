import { useState, useEffect } from "react";
import useGlobal from '../../../store';
import Textfield from "../../components/textfield";
import { SHARE } from "../../../settings/constants";
import ToggleSlider from "../../components/toggleslider";
import ShareToEmails from "./share.to.emails";

import { 
  
  usePopup,
  PopupTitle, 
  PopupActionButtons, 
  PopupContentPadding, 

} from '../popup';

import { WORDBOOK } from "../../../locales";
import s from "./popup.share.module.scss";

const PopupShare = () => {

  const [ globalState, globalActions ] = useGlobal();

  const { 

    popup_opened,
    scheme_id,
    scheme_shared_mode,
    scheme_shared_emails,
    locale
  
  } = globalState;

  const { popup, share } = globalActions;

  const wordbook = WORDBOOK[ locale ];
  const t = wordbook.popupShare;
  const { SHARE_TEXT } = wordbook.constants;

  const [ shareMode, setShareMode ] = useState( scheme_shared_mode );
  const [ url, setUrl ] = useState(`https://app.database-design.ru/scheme/${ scheme_id }`);

  useEffect(() => {

    if ( popup_opened === "share" && shareMode !== scheme_shared_mode ) {
      
      setShareMode( scheme_shared_mode );
    
    }
    
    setUrl(`https://app.database-design.ru/scheme/${ scheme_id }`);

  }, [ scheme_shared_mode, popup_opened, scheme_id ])

  async function changeShareMode( mode ) {

    setShareMode( mode );

    share.changeMode({ 
      
      scheme_id: scheme_id,
      mode
    
    });

  }

  const CONTENT = (

    <div>

      <PopupTitle>{ t.title }</PopupTitle>

      <PopupContentPadding>

        { t.subtitle }

        <ToggleSlider

          selected = { shareMode }
          select = { changeShareMode }
          list = { SHARE_TEXT }
          className = { s.toggleslider }

        />

        { shareMode !== SHARE.ONLY_ME &&

          <Textfield

            title ={ t.url }
            value = { url }
            set = { () => { setUrl( url ) } }

          />

        }

        { shareMode === SHARE.EMAILS &&
        
          <ShareToEmails
          
            users = { scheme_shared_emails }
            add   = { form => {

              share.addEmail({ 
                
                scheme_id, 
                ...form 
              
              }); 

            }}

            remove = { form => {

              share.removeEmail({ 
                
                scheme_id, 
                ...form 
              
              }); 

            }}

            editAccess = { form => {

              share.changeAccessForEmail({ 
                
                scheme_id, 
                ...form 
              
              }); 

            }}
            
          />

        } 
     
      </PopupContentPadding>

      <PopupActionButtons

        confirmText = { t.confirmText }
        confirm = { popup.hide }
        hideCancel

      />

    </div>

  );

  const thisPopup = usePopup({ name: "share", content: CONTENT })

  return thisPopup;

}

export default PopupShare;