import React, { useState, useEffect, Fragment } from "react";
import cookie from 'react-cookies';
import { useNavigate } from "react-router-dom";
import Container from "../../components/container";
import useGlobal from "../../../store";
import ButtonBigAdd from "../../components/button-big-add";
import SchemeRow from "../../components/scheme.row";
import Textfield from "../../components/textfield";
import Textarea from "../../components/textarea";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import cssIf from "../../../scripts/helpers/class.add.if";
import Button from "../../components/button";
import { THEME } from "../../../settings/constants";
import { ReactComponent as SchemeIcon } from "./icons/decor-icon.svg";
import { WORDBOOK } from "../../../locales";
import './schemes.scss';

const Schemes = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { theme, schemes, schemes_shared, user_tariff, locale } = globalState;
  const { scheme } = globalActions;

  const navigate = useNavigate();
  const [ Dummy, reloadDummy ] = useReloadDummy();

  const wordbook = WORDBOOK[ locale ];
  const t = wordbook.schemes;

  const [ showAddForm, setShowAddForm ] = useState( false );

  useEffect(() => { 
    
    scheme.all( reloadDummy ); 

    if ( !cookie.load('dbd-scale') ) { cookie.save('dbd-scale', '4') }
  
  }, []);

  function handleAddScheme() {

    if ( user_tariff?.id === 0 && schemes.length > 0 ) {
      alert(t.alert);
      return;
    }

    setShowAddForm( !showAddForm )

  }

  return (

    <Container className = {`${ cssIf( theme === THEME.LIGHT, `container__main--light` ) }`}>

      <div className = "schemes_container">

        <div className = {`schemes ${ cssIf( showAddForm, "schemes--add" )}`}>

          <div className = "schemes__head relative flex justify-between items-center">
          
            <div className = "schemes__head__title">{ t.title }</div>

            <ButtonBigAdd 

              text = { !showAddForm ? t.btnCreate : t.btnCancel }
              action = { handleAddScheme } 

            />

            <FormAddScheme 
            
              wordbook = { wordbook.addSchemes }
              action = { ( n, d ) => { scheme.add( n, d ); setShowAddForm( false ); }}
              
            />

          </div>

          <Dummy />

          <div className = "schemes__list">

            { schemes.length > 0 
              
              ? schemes.map(( el, key ) => (

                <SchemeRow

                  key = { key }
                  id = { el.id }
                  name = { el.name }
                  description = { el.description }
                  actionOpen = { ( id ) => window.location = `/scheme/${ id }` }

                />

              ))
            
              : <div className = "fullscreen-tip">{ t.noSchemes}</div>
            
            }
            
          </div>

          { schemes_shared.length > 0 &&
          
            <Fragment>

              <br />

              <div className = "schemes__head relative flex justify-between items-center">
              
                <div className = "schemes__head__title">{ t.available }:</div>

              </div>
              
              <div className = "schemes__list">

                { schemes_shared.map(( el, key ) => (

                  <SchemeRow

                    key = { key }
                    id = { el.id }
                    name = { el.name }
                    description = { el.description }
                    actionOpen = { ( id ) => navigate(`/scheme/${ id }`) }

                  />

                ))}
                
              </div>

            </Fragment>

          }
            
        </div>

      </div>

    </Container>

  );

}

export default Schemes;

const FormAddScheme = ({ action, wordbook }) => {

  const t = wordbook;

  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");

  function addScheme() {

    if ( name === "" ) { alert(t.alert); return false; }
    const isMobile = window.innerWidth <= 920;
    cookie.save('dbd-scale', !isMobile ? '4' : '2.2' );
    action( name, description );
    setName("");
    setDescription("");

  }

  return (

    <div className = "scheme_add">

      <div className = "scheme_add__title">{ t.title }</div>
      <SchemeIcon className = "scheme_add__icon" />

      <Textfield

        title = { t.name }
        placeholder = { t.namePlaceholder }
        value = { name }
        set = { setName }

      />

      <Textarea

        title = { t.description }
        placeholder = { t.descriptionPlaceholder }
        value = { description }
        set = { setDescription }

      />

      <Button action = { addScheme }>{ t.add }</Button>
    
    </div>

  );

}