import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import './constructor.scss';
import Container from '../../components/container';
import useReloadDummy, { Dummy as GlobalDummy } from '../../../scripts/hooks/use.reload.dummy';
import ButtonBigAdd from '../../components/button-big-add';
import useGlobal from '../../../store';
import DynamicArrows from '../../components/arrows';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import RenderTables from '../../components/render.tables';
import PopupHelp from '../../popups/help';
import cssIf from '../../../scripts/helpers/class.add.if';
import PopupShare from '../../popups/popup.share';
import { SHARE_ACCESS, THEME } from '../../../settings/constants';
import { SENSIVITY } from '../../../settings/user.mouse.settings';
import MessageFromRikky from '../../components/message.from.rikky';
import PopupConfirm from '../../popups/popup.confirm';
import useXarrow from '../../components/dynamic.arrows/useXarrow';
import Xwrapper from '../../components/dynamic.arrows/xwrapper';
import { WORDBOOK } from "../../../locales";
import SidePanelTools from '../../components/side.panel.tools';
// import RenderWorkspaceAnchors from '../../components/render.workspace.anchors';

const Constructor = () => {

  const updateXarrow = useXarrow();
  const [ Dummy, reloadDummy ] = useReloadDummy();
  const [ globalState, globalActions ] = useGlobal();

  const { 
    
    dummy,
    theme,
    scheme_access,
    scheme_color_groups,
    scheme_color_groups_visible,
    user_tariff, 
    user_controller,
    tables, 
    showHelpPopup, 
    isTablesOnTopLayer, 
    disableOpenedHelp,
    locale,
  
  } = globalState;

  const { changeStates, scheme, popup } = globalActions;

  const t = WORDBOOK[ locale ].constructor;

  const canEdit = scheme_access === SHARE_ACCESS.EDIT;

  const { id } = useParams();
  const navigate = useNavigate();
  const [ disablePinchZoom, setDisablePinchZoom ] = useState( false );

  const initialScale = +cookie.load('dbd-scale') || 4;
  const [ currentScale, setCurrentScale ] = useState( initialScale );

  const popupShare = PopupShare();
  const popupConfirm = PopupConfirm();

  const setTablesOnTopLayer = async bool => changeStates({ isTablesOnTopLayer: bool })

  function refreshArea() {

    reloadDummy();
    updateXarrow();

  }
  
  useEffect(() => {
    
    !id && navigate('/'); 
    globalActions.scheme.get( id, refreshArea );

    document.body.style.overflow = 'hidden';
    document.getElementById('root').style.overflow = 'hidden';

    return () => {
      
      document.body.style.overflow = 'auto';
      document.getElementById('root').style.overflow = 'auto';
      
    }

  }, []);

  useEffect(() => { updateXarrow() }, [ scheme_color_groups_visible.length ]);

  useEffect(() => { disableOpenedHelp && globalActions.sign.disableDefaultOpenedHelp(); }, [ disableOpenedHelp ])

  async function handleUpdateTablePosition( table_id, position ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].position = position;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    refreshArea();

  }

  async function handleUpdateRowsPosition( table_id, columns ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].columns = columns;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleAddTable() {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    const isMobile = window.innerWidth <= 920;

    let initialX = 800;
    let initialY = 880;

    if ( isMobile ) {

      initialX = 1280;
      initialY = 800;

    }

    let offsetX = 400;
    let offsetY = 150;

    let positionX = initialX + ( ( tables.length % 5 ) * offsetX );
    let positionY = initialY + ( Math.floor( tables.length / 5 ) * offsetY );

    const newID = Date.now();

    const initialTable = {

      id: newID,
      name: "new table",
      color: "#34A400",
      position: {
        x: positionX,
        y: positionY
      },
      columns: [
        {
          id: 1,
          name: "id",
          type: 0,
          isPrimaryKey: true,
          isNotNull: true,
          autoIncrement: true
        }
      ]
    
    }

    const temp = tables;
    temp.push( initialTable );
    scheme.save( id, temp );
    changeStates({ tables: temp });
    setDisablePinchZoom( false );
    reloadDummy();

  }

  async function handleDuplicateTable( table ) {

    console.log(`handleDuplicateTable( table )`, table );
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let initialX = 800;
    let initialY = 880;

    let offsetX = 400;
    let offsetY = 150;

    let positionX = initialX + ( ( tables.length % 5 ) * offsetX );
    let positionY = initialY + ( Math.floor( tables.length / 5 ) * offsetY );

    const newID = Date.now();

    const tableData = JSON.parse( JSON.stringify( table ) )

    const initialTable = {

      ...tableData,
      id: newID,
      position: {
        x: positionX,
        y: positionY
      },
    
    }

    console.log(`handleDuplicateTable newTable`, initialTable );

    const temp = tables;
    temp.push( initialTable );
    // changeStates({ tables: [] });
    scheme.save( id, temp );
    changeStates({ tables: temp });
    setDisablePinchZoom( false );
    reloadDummy();

  }

  async function handleRenameTable( args ) {

    console.log(`handleRenameTable args:`,args);

    const {

      table_id, 
      name, 
      tip

    } = args;
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }
    //// console.log(`handleRenameTable(${table_id},${name})`);
    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].name = name;
        t[ i ].tip = tip;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleAssignColorGroup( table_id, group_id ) {
    
    if ( !user_tariff?.active ) { navigate('/tariff'); return; }
    //// console.log(`handleRenameTable(${table_id},${name})`);
    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        t[ i ].groupID = group_id;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleRemoveTable( table_id ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }
    
    const table = tables.find( tb => +tb.id === +table_id );
    const skipConfirm = table.columns.length <= 1;

    if ( skipConfirm ) {

      removeTable( table_id );
      return;

    }

    popup.confirm({
      
      text: t.deleteTable(table?.name),
      action: () => removeTable( table_id )
      
    });

  }
  
  async function removeTable( table_id ) {

    console.log(`tables.remove table id`, table_id );
    
    let t = tables;
    const temp = [];

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id !== table_id ) {

        let cancelForeign = t[ i ].columns.find( col => col?.foreign && col.foreign.indexOf(`${ table_id }-`) !== -1 );
        if ( cancelForeign ) { cancelForeign.foreign = false; }

        temp.push( t[ i ] );

      }

    }

    console.log(`tables.after`, temp );

    changeStates({ tables: [] });
    await scheme.save( id, temp );
    changeStates({ tables: temp });
    reloadDummy();

  }

  async function handleAddColumn( table_id, data ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    console.log(`handleAddColumn(${table_id})`, data);
    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        let c = t[ i ].columns;

        const newID = Date.now();

        c.push({ 

          ...data,
          id: newID

        });

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleEditColumn( table_id, column_id, data ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( parseInt( t[ i ].id ) === parseInt( table_id ) ) {

        let c = t[ i ].columns;

        for ( let j = 0; j < c.length; j++ ) {

          if ( parseInt( c[ j ].id ) === parseInt( column_id ) ) {

            c[ j ] = data;

          }

        }

        t[ i ].columns = c;

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    reloadDummy();

  }

  async function handleRemoveColumn( table_id, column_id ) {

    if ( !user_tariff?.active ) { navigate('/tariff'); return; }

    const table = tables.find( tb => +tb.id === +table_id );
    const column = table.columns.find( tc => +tc.id === +column_id );

    popup.confirm({

      text: t.deleteTableColumn(table?.name, column?.name),
      action: () => removeColumn( table_id, column_id )

    });

  }

  async function removeColumn( table_id, column_id ) {

    let t = tables;

    for ( let i = 0; i < t.length; i++ ) {

      if ( t[ i ].id === table_id ) {

        let c = [];

        for ( let j = 0; j < t[ i ].columns.length; j++ ) {

          t[ i ].columns[ j ].id !== column_id && c.push( t[ i ].columns[ j ] );

        }   
        
        t[ i ].columns = c;

      } else {

        let cancelForeign = t[ i ].columns.find( col => col?.foreign === `${ table_id }-${ column_id }` );
        if ( cancelForeign ) { cancelForeign.foreign = false; }

      }

    }

    scheme.save( id, t );
    changeStates({ tables: t });
    updateXarrow();
    reloadDummy();

  }

  const TransformWrapperIgnoreClasses = ["input", "textarea"]

  function saveScaleToCookies( scl ) { 
    
    setCurrentScale( scl );
    cookie.save('dbd-scale', scl, { path: '/' } ); 
  
  }

  const maxScale = 5;

  const minScale = useMemo(() => {

    let scale = 1;

    if ( tables.length >= 20 ) scale = 0.8;
    if ( tables.length >= 30 ) scale = 0.65;
    if ( tables.length >= 40 ) scale = 0.5;
    
    return scale;

  }, [ tables.length ]);

  useEffect(() => {

    !tables.length && disablePinchZoom && setDisablePinchZoom( true )
    tables.length && !disablePinchZoom && setDisablePinchZoom( false )

  }, [ tables.length ]);

  return (

    <Fragment>

      { !showHelpPopup ? "" :
      
        <PopupHelp close = { () => { changeStates({ showHelpPopup: false }) }} />
        
      }

      <Container 
      
        className = {`container__main container--constructor ${ cssIf( theme === THEME.LIGHT, `container__main--light` ) }`}
        popups = {(
          <Fragment>
            { popupConfirm.initialize }
            { popupShare.initialize }
          </Fragment>
        )}
      
      >

        <div 
        
          id = "workspace"
          className = {`constructor ${ cssIf( isTablesOnTopLayer, 'constructor--tables-on-top-layer') }`} 
          
          onMouseDown = { ( e ) => { 

            // console.log(`e.target`,e.target);
            // console.log(`e.target.className`,e.target.className.toString());
            // console.log(`e.target.id`,e.target.id);

            const isWorkspace = e.target?.className?.toString().includes('react-transform-component') || e.target?.id === 'space';
            isWorkspace && setDisablePinchZoom( false );

          }}

        >
          
          <Dummy />
          <Dummy random = { dummy } />
          
          { scheme_color_groups.length > 1 && <SidePanelTools /> }
          
          <Xwrapper>
              
            { tables.length > 0 && tables.map(( table, key ) => (
              
              <DynamicArrows

                key = { key }
                table_id = { table.id } 
                table_group_id = { table.groupID } 
                data = { table.columns } 
                zoom = { currentScale }

              />

            ))}

            <TransformWrapper
              // initialPositionX={0}
              // initialPositionY={0}
              centerOnInit = { true }
              initialScale={ initialScale }
              minScale={ minScale }
              maxScale={ maxScale }
              
              doubleClick = {{ disabled: true }}
              zoomAnimation = {{ disabled: true }}
              alignmentAnimation = {{ disabled: true }}
              velocityAnimation = {{ disabled: true }}
              wheel = {{ 
                disabled: disablePinchZoom,
                excluded: TransformWrapperIgnoreClasses,
                step: SENSIVITY[ user_controller ]
              }}
              pinch = {{ 
                disabled: disablePinchZoom,
                excluded: TransformWrapperIgnoreClasses 
              }}
              panning = {{
                disabled: disablePinchZoom,
                velocityDisabled: true,
                excluded: TransformWrapperIgnoreClasses
              }}
              onZoom = { updateXarrow }
              onZoomStop = { ( event ) => { updateXarrow(); saveScaleToCookies( event.state.scale ); }}
              onPinching = { updateXarrow }
              onPinchingStart = { updateXarrow }
              onPinchingStop = { updateXarrow }
              onPanning = { updateXarrow }
              onPanningStop = { updateXarrow }

            >

              <TransformComponent>              

                <div id = "space" className = "constructor__space">

                  <GlobalDummy dummy = { dummy } />

                  {/* <RenderWorkspaceAnchors /> */}
                 
                  { tables.length > 0 && 

                    <RenderTables

                      tables = { tables }
                      canEdit = { canEdit }
                      areaScale = { currentScale }
                      renameTable = { handleRenameTable }
                      duplicateTable = { handleDuplicateTable }
                      removeTable = { handleRemoveTable }
                      addColumn = { handleAddColumn }
                      editColumn = { handleEditColumn }
                      removeColumn = { handleRemoveColumn }
                      assignColorGroup = { handleAssignColorGroup }
                      updateTablePosition = { handleUpdateTablePosition }
                      updateRowsPosition = { handleUpdateRowsPosition }
                      setDisablePinchZoom = { setDisablePinchZoom }
                      setTablesOnTopLayer = { setTablesOnTopLayer }

                    />
                                  
                  }

                </div>

              </TransformComponent>                

              { canEdit &&
              
                <ButtonBigAdd 

                  text = { t.btnNewTable }
                  action = { handleAddTable } 

                />
              
              }

            </TransformWrapper>
          
          </Xwrapper>

          { tables.length === 0 && 
          
            <div className="fullscreen-tip">
              { canEdit ? t.textNewTable: t.noTable }
            </div>
                  
          }

          <MessageFromRikky

            title = { t.title }
            text = { t.text }
            subtitle = { t.subtitle }

          />

        </div>

      </Container>

    </Fragment>

  );

}

export default Constructor;