import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";
import "./help.popup.scss";

const PopupHelp = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { quickHelpImageUrl } = WORDBOOK[ globalState.locale ];

  return (
  
    <div 

      className="popup_help" 
      onClick={ close } 
      style = {{ backgroundImage: `url(${ quickHelpImageUrl })` }}

    />
  
  )

}
export default PopupHelp;