import React, {Fragment} from "react";
import { TARIFF_PRICE } from "../settings/constants";

export const ru = {

  currentLanguage: "Русский",
  quickHelpImageUrl: "/images/quickguide/ru.jpg",

  signin: {
    email: 'Электронная почта',
    no_profile: 'у меня нет аккаунта',
    signup: 'Зарегистрироваться',
    enter: 'Войти',
  },

  signup: {
    email: 'Электронная почта',
    signup: 'Зарегистрироваться',
    auth: 'Авторизоваться',
    hasAccount: 'у меня уже есть аккаунт',
    phone:
      <Fragment>
        Телефон
        <span style = {{ opacity: 0.6, paddingLeft: '2px'}}>
          (не обязательно)
        </span>
      </Fragment>,
    password: 'Пароль',
    passwordPlaceholder: 'Введите пароль',
    passwordRepeat: 'Повторите пароль',
    passwordRepeatPlaceholder: 'Повторите пароль',
    offerSTART: 'Я принимаю условия',
    offer1: 'Договора-оферты',
    offer2: 'Политики обработки персональных данных',
    offer3: 'и даю согласие на обработку моих персональных данных',
    bad_pass: 'Пароли не совпадают!',
  },

  tariff: {
    p1: <Fragment>Используй <br className='mobile'/>Database Design <br className='mobile'/>на максимум!</Fragment>,
    p2: <Fragment>Поддерживая разработчика, <br className='mobile'/>и внося вклад в развитие проекта</Fragment>,
    p3: 'Приятный интуитивный интерфейс конструктора схем баз данных',
    p4: 'Самый наглядный просмотр визуальной схемы БД из аналогов',
    p5: 'Делись визуальными схемами баз данных с командой и заказчиками',
    p6: 'Экспортируй визуальные схемы в SQL-дампы (со связями и индексами)',
    p7: 'Создавай неограниченное количество проектов',
    p8: 'Для работы требуется доступ в интернет с любого устройства',
    p9: 'По окончанию подписки никаких авто-платежей не будет',
    p10: 'Поддержи проект!',
    p11: 'Закинь автору респект',
    priceMonth: `Стоимость: ${ TARIFF_PRICE.MONTH.RUB }р в месяц`,
    priceDayTariffMonth: <Fragment>Это всего <b>{ ( TARIFF_PRICE.MONTH.RUB / 30 ).toFixed( 0 ) }</b> рублей в день!</Fragment>,
    priceDayTariffYear: <Fragment>Это всего <b>{ ( TARIFF_PRICE.YEAR.RUB / 365 ).toFixed( 0 ) }</b> рублей в день!</Fragment>,
    payMonth: `Оплатить 1 месяц: ${ TARIFF_PRICE.MONTH.RUB }р`,
    priceYear: `Всего ${ ( TARIFF_PRICE.YEAR.RUB / 12 ).toFixed( 0 ) }р в месяц при оплате за год!`,
    payYear: `Оплатить за год: ${ TARIFF_PRICE.YEAR.RUB }р`,
    p12: 'Твои проекты баз данных ещё никогда не выглядели так качественно!',
  },

  schemes: {
    alert: `В демо-режиме можно создать только одну визуальную схему. Если тебе понравится конструктор, ты сможешь завтра оплатить его, стоимость составляет всего ${ TARIFF_PRICE.MONTH.RUB }р в месяц, это как одно такси!`,
    title: 'Мои схемы',
    btnCreate: 'Создать',
    btnCancel: 'Отмена',
    noSchemes: 'Вы ещё не создали ни одну схему',
    available: 'Доступные мне',
  },

  addSchemes: {
    alert: 'Название не может быть пустым!',
    title: 'Новая схема',
    name: 'Название:',
    namePlaceholder: 'На английском языке',
    description: 'Описание:',
    descriptionPlaceholder: 'Не обязательно',
    add: 'Добавить',
  },

  payment: {
    title: 'Успешно оплачено!',
    subtitle: 'Ваш тариф будет действовать до',
    thanking: 'Приятного использования!',
    btnEnter: 'Начать использование',
    cancelPayment: 'Платёж отменён',
    cancelPaymentThanking: 'Вы прервали оплату тарифа',
    cancelPaymentSubtitle: 'Для связи с техподдержкой напишите в чат',
    cancelPaymentRepeat: 'Попробуйте ещё раз!',
    return: 'Вернуться к тарифам'

  },

  constructor: {
    dublicateTable: 'Создать копию этой таблицы',
    deleteTable: name => <Fragment>Вы хотите удалить таблицу { name }?<br/>Это действие необратимо!</Fragment>,
    deleteTableColumn: (t, c) => <Fragment>Вы хотите удалить столбец { c } из таблицы { t }?<br/>Это действие необратимо!</Fragment>,
    btnNewTable: 'Новая таблица',
    textNewTable: <Fragment>Начните проектирование <br className="mobile"/>схемы базы данных - добавьте <br className="mobile"/>первую таблицу!</Fragment>,
    noTable:"В этой схеме ещё нет ни одной таблицы",
    title:
      <Fragment>
        Я хочу сделать Database Design лучше, удобнее и полезнее для вас!
      </Fragment>,
    text:
      <Fragment>
        И только вы можете помочь мне это сделать! Пишите в чат под этим сообщением, <br/>
        что вам нравится и не нравится, что удобно или не удобно, какого функционала <br/>
        не хватает. Что добавить, чтобы этот проект был для вас максимально полезен?
      </Fragment>,
    subtitle:
      <Fragment>
        Ваше мнение очень важно для меня! <span>Андрей Рик, создатель проекта.</span>
      </Fragment>,

  },

  sidePanelTools: {

    showTableGroups: 'Показать группы таблиц',
    colorGroupNameEmpty: 'Без названия',

  },

  columnSettings: {
    lengthSymbols: 'Длина в символах:',
    lengthBytes: 'Длина в байтах:',
    lengthNumeric: 'Количество цифр:',
    lengthSigns: 'Допустимые значения через запятую:',

    name: 'Название:',
    namePlaceholder: 'На англ.языке без пробелов',

    checkboxTipNotNull: 'Не может быть пустым',
    checkboxTipUnique: 'Значение не должно повторяться',
    checkboxTipPrimaryKey: 'Первичный ключ',
    checkboxTipAutoIncrement: 'Автозаполнение по нарастающей',

    selectForeign: 'Связь с другой таблицей:',
    selectOnDelete: 'При удалении:',
    selectOnUpdate: 'При обновлении:',
    selectSetType: 'Тип данных:',
    textDecimal: 'Дробная часть:',
    textDefaultValue: 'Значение по умолчанию:',
    textDefaultValuePlaceholder: 'Значение по умолчанию:',
    textComment: 'Комментарий:',
    textCommentPlaceholder: 'Не обязательно',
    textUnique: 'Этот столбец автоматически индексируется, так как отмечен как отмечен как UNIQUE',
    textUniqueTitle: 'Индекс ускоряет скорость поиска значений в этом столбце во много раз',

    checkboxIsIndexTitle: 'Индексировать этот столбец',
    checkboxIsIndexTip: 'Индекс создается на поле в таблице по которому будет происходить поиск. Это ускоряет скорость поиска в разы. Также обязательно ставьте индексы для полей участвующих в соединениях таблиц по INNER JOIN, LEFT JOIN, RIGHT JOIN. Поскольку индекс занимает место, то индексировать нужно только те поля, по которым происходит выборка.',

    selectIndexMethod: 'Метод индекса:',
    selectIndexType: 'Тип индекса:',

    textareaComment: 'Комментарий:',
    textareaCommentPlaceholder: 'Не обязательно',
  },

  colorAndDescription: {
    description: 'Краткое описание таблицы:',
    placeholder: 'Не обязательно',
    colorGroupNamePlaceholder: 'Например Пользователи',
    colorGroupNameEmpty: 'Без названия',
    addNewGroup: 'Добавить новую группу',
    tableGroup: 'Группа таблиц:',
    pushToChoose: '(нажмите, чтобы выбрать)',
    chooseTableColor: 'Выбрать цвет шапки таблицы',
    addGroup: 'Добавление группы',
    changeGroup: 'Изменение группы',
    tableGroupName: 'Название группы таблиц:',
    headerColor: 'Цвет шапок таблиц группы:',
    editColorGroupTip: 'Изменить название группы или цвет',
    showAllTables: 'Показать все таблицы',
  },

  indexEnums: {
    INDEX_TYPES: {
      NORMAL: `Стандартный индекс по значению. Позволяет быстро находить строки, соответствующие определенным значениям или диапазонам значений.`,
      FULLTEXT: `Полнотекстовый индекс. Подходит только для типов данных *TEXT и VARCHAR. Используется для выполнения поиска по ключевым словам или фразам в текстовом содержимом столбца.`,
      SPATIAL: `Индекс пространственных данных. Подходит только для геометрических типов данных. Используется для эффективного поиска географических объектов и выполнения операций, таких как поиск ближайших объектов или определение зоны, попадающей в определенную область.`,
    },
    INDEX_METHODS: {
      BTREE: `Самый распространенный метод индексации в MySQL, который поддерживает эффективный поиск по значениям. Он хранит ключи в отсортированном порядке на основе балансированного дерева`,
      HASH: `Хеширование (Hash) - этот метод использует хеш-функции для вычисления хеш-значений ключей и создания хеш-таблицы для быстрого поиска. Однако, индексы на основе хеширования не поддерживают диапазонные запросы`,
      GIN: `GIN (Generalized Inverted Index) - это метод индексации, который широко используется для полнотекстового и поиска по массивам данных в JSON или массивах`,
    }
  },

  dataTypes: {
    DATA_TYPES_TIPS: {
        i0: `Хранит целое число, без дробной части в диапазоне от -2.147.483,648 до 2.147.483.647, или беззнаковые числа от 0 до 4.294.967.295`,
       i15: `Хранит целое число, без дробной части в диапазоне от -128 до 127, или беззнаковые числа от 0 до 255.`,
       i16: `Хранит целое число, без дробной части в диапазоне от -32.768 до 32.767, или беззнаковые числа от 0 до 65,535`,
       i17: `Хранит целое число, без дробной части в диапазоне от -8.388.608 до 8.388.607, или беззнаковые числа от 0 до 16.777.215`,
       i18: `Хранит целое число, без дробной части в диапазоне от -9.223.372.036.854.775.808 до 9.223.372.036.854.775.807, или беззнаковые числа от 0 до 18.446.744.073.709.551.615`,
       i10: `Хранит число с плавающей запятой в диапазоне от -3.402823466E+38 до -1.175494351E-38, 0 и от 1.175494351E-38 до 3.402823466E+38. Точность чисел в типе FLOAT может меняться в зависимости от их масштаба. Обычно, точность FLOAT составляет около 7 цифр. Однако, стоит помнить, что тип данных FLOAT представляет приближенное представление чисел с плавающей запятой и может иметь потерю точности. Если точность критически важна для ваших данных, может быть лучше использовать тип данных DECIMAL, который обеспечивает точное хранение чисел с фиксированной точностью и масштабом. Важно знать, что превышение диапазона чисел в FLOAT может привести к ошибкам округления, чтобы избежать этого рекомендуется тщательно рассчитывать необходимые пределы и проверять данные перед их сохранением в поле типа FLOAT.`,
       i19: `Хранит число с плавающей запятой в диапазоне от -1.7976931348623157E+308 до -2.2250738585072014E-308, 0 и от 2.2250738585072014E-308 до 1.7976931348623157E+308. Тип данных DOUBLE является приближенным представлением чисел с плавающей запятой и может иметь потерю точности в некоторых случаях. Поэтому, для высокой точности без потери данных может быть лучше использовать тип данных DECIMAL, который обеспечивает точное хранение чисел с фиксированной точностью и масштабом.`,
       i20: `Хранит число (в т.ч. дробное), с фиксированной точностью и масштабом. Представляет числа в формате десятичной дроби, где точность определяется количеством цифр в числе, а масштаб - количеством знаков после запятой. Тип данных DECIMAL в отличие от типов FLOAT и DOUBLE предоставляет хранение чисел без потери точности. Стоит учитывать, что тип данных DECIMAL требует больше памяти для хранения данных по сравнению с типами FLOAT и DOUBLE. Кроме того, при выполнении математических операций над числами типа DECIMAL необходимо обращать внимание на возможное переполнение и необходимость округления результатов.`,
        i1: `Строка переменной длины до 65.535 символов. Причем в памяти хранится именно та длина, которая была указана при создании. VARCHAR занимает меньше места, чем CHAR, но подвержен фрагментации и из-за этого может проигрывать в скорости обработки данных. Поле типа varchar может быть использовано для создания индексов, включая индекс по первичному ключу. С text индексирование ограничено, и некоторые операции с индексированными столбцами text могут быть ограничены или недоступны.`,
        i2: `Строка переменной длины до 65.535 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.`,
       i21: `Строка фиксированной длины до 255 символов. Если длина вставляемой записи меньше, то MySQL автоматически дополняет значение пробелами`,
        i6: `Строка переменной длины до 255 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.`,
        i7: `Строка переменной длины до 16.777.215 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.`,
        i8: `Строка переменной длины до 4.294.967.295 символов. Обрабатывается как символьная строка. В ней хранятся именно набор символов, а значения сортируются и сравниваются на основе сопоставления набора символов. В отличие от varchar, text хранит данные в отдельном блоке, а не в самой строке таблицы, что может повлиять на производительность при выполнении операций чтения и записи.`,
       i28: `Двочиные данные фиксированной длины.`,
       i29: `Двочиные данные переменной длины.`,
       i12: `Двоичные данные до 65.535 байт (64 Kb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.`,
       i11: `Двоичные данные до 255 байт. Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.`,
       i13: `Двоичные данные до 16.777.215 байт (16 Mb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.`,
       i14: `Двоичные данные до 4.294.967.295 байт (4 Gb). Может хранить любые файлы или мультимедиа-контент. Операции сортировки и сравнения основаны на числовых значениях байтов.`,
       i22: `Только дата в формате YYYY-MM-DD. Допустимые значения от 1000-01-01 до 9999-12-31`,
        i3: `Дата и время в формате YYYY-MM-DD HH:MM:SS. Допустимые значения от 1000-01-01 00:00:00 до 9999-12-31 23:59:59`,
        i4: `Дата и время. Хранится в виде количества секунд, прошедших с 1 января 1970 года по гринвичу. Занимает в два раза меньше места, чем тип DATETIME. Но при этом диапазон ограничен значениями от 1970-01-01 00:00:01 до 2038-01-09 03:14:07`,
       i23: `Только время в формате HH:MM:SS. Допустимые значения от 00:00:00 до 23:59:59`,
       i24: `Только год в формате YYYY или YY. Допустимые значения от 1901 до 2155 или от 70 до 69 (1970 — 2069)`,
       i25: `Хранит только одно значение из установленного списка допустимых значений, занимает 1-2 байта.`,
       i26: `Хранит одновременно до 64 значений из установленного списка допустимых значений, занимает от 1 до 8 байт.`,
       i30: `Хранит значения геометрии любого типа. Другие типы с одиночным значением (POINT, LINESTRING и POLYGON) ограничивают их значения специфическим типом геометрии.`,
       i31: `Используется для хранения двухмерных географических координат - широты и долготы.`,
       i32: `Используется для хранения геометрии линии, состоящей из последовательности точек. Представляет собой набор упорядоченных точек, соединенных прямыми отрезками.`,
       i33: `Используется для хранения и работы с географическими полигонами. Полигон представляет собой закрытую фигуру на плоскости, ограниченную линиями соединения вершин. Данный тип данных полезен, когда вам нужно хранить информацию о географических объектах, таких как границы территорий, места интереса или формы земельных участков.`,
       i35: `Хранит совокупность объектов двухмерных географических координат - широты и долготы.`,
       i37: `Хранит совокупность объектов географических полигонов. Полигон представляет собой закрытую фигуру на плоскости, ограниченную линиями соединения вершин. Данный тип данных полезен, когда вам нужно хранить информацию о географических объектах, таких как границы территорий, места интереса или формы земельных участков.`,
       i36: `Хранит совокупность объектов геометрии линии, состоящей из последовательности точек. Представляет собой набор упорядоченных точек, соединенных прямыми отрезками.`,
       i34: `Хранит совокупность объектов любого типа. Другие типы совокупности (MULTIPOINT, MULTILINESTRING, MULTIPOLYGON и GEOMETRYCOLLECTION) ограничивают элементы совокупности имеющими специфический тип геометрии.`,
        i5: `Хранит Булево значение "истина" или "ложь" (true/false)`,
        i9: `Хранит данные в формате JSON`,
       i27: `Тип данных UUID обеспечивает уникальность идентификаторов даже в условиях распределенной системы и позволяет избежать конфликтов идентификаторов при интеграции нескольких источников данных или при работе с параллельными процессами. Обратите внимание, что в MySQL нет встроенного типа данных UUID (в отличии от например PostgreSQL и MongoDB), но uuid можно сгенерировать на стороне бэкенда и хранить в виде VARCHAR или ИШТФКН`,
    },

    DATA_TYPES_GROUPS: {
      NUMBER: 'Числовые',
      DECIMAL: 'Дробные',
      TEXT: 'Текстовые',
      BINARY: 'Бинарные',
      DATETIME: 'Дата/время',
      DATETIME_TIP: `Типы данных, которые позволяют работать с датой и временем.`,
      CUSTOM: 'Составные',
      CUSTOM_TIP: '`Строковые типы данных, которые могут хранить значения только из заранее определенного списка. Несмотря на то, что список значений состоит из строк, в самих таблицах хранятся только числа, которые ассоциированы со справочником возможных значений. Поэтому они занимают мало места.`',
      SPATIAL: 'Геометрические',
      OTHER: 'Прочие',
    }
  },

  toggleMouse: {
    mouse: 'Выберите, если используете мышь, а не сенсорную панель',
    touchpad: 'Выберите, если используете сенсорную панель, а не мышь',
  },

  header: {
    USER_MENU: {
      out: 'Выйти'
    },
    toList: 'к списку схем',
    helpIcon: 'Краткая инструкция',
    signin: 'Войти',
    register: 'Зарегистрироваться',
    popup: 'Вы действительно хотите удалить проект и все его таблицы? Это действие необратимо!',
  },

  relationSelector: {
    no_selected: "Не выбрано",
    err: 'getTableNameAndColumns не нашел таблицу с ID',
    err_not_found: 'Ошибка: не найдено',
    del_rel: 'удалить связь'
  },

  shareToEmails: {
    text: 'Вы дали доступ к проекту:'
  },

  popupConfirm: {
    text: 'Подтвердить'
  },

  relationColumnSelector: {
    title: 'Связь с таблицей:',
    chooseOtherTable: 'выбрать другую',
    reltabsel__title: 'Сослаться на столбец:'
  },

  popupShare: {
    title: 'Настройки общего доступа',
    subtitle: 'Кто может просматривать этот проект?',
    url: 'Публичная ссылка:',
    confirmText: 'Вернуться'
  },

  schemeName: {
    text1: 'сохранить SQL-дамп',
    t2: 'настройки общего доступа',
    t3: 'удалить проект',
    alert: 'Название не может быть пустым!',
  },

  row: {
    index: 'Индекс',
    primarykey: 'Первичный',
    foreignkey: 'Вторичный',
    key: 'ключ',
    delete_col: 'Удалить столбец',
  },

  toggleTheme: {
    dark: 'Тёмная тема',
    light: 'Светлая тема',
  },

  radioSelector: {
    text: 'Не выбрано'
  },

  searchfield: {
    text: 'Поиск',
  },

  relationTableSelector: {
    text: 'Выбор таблицы:'
  },

  messageFromRikky: {
    close1: 'напомнить позже',
    close2: 'я уже написал(а)'
  },

  loading: {
    text: 'Ожидайте, данные загружаются'
  },

  selectfield: {
    placeholder: 'Нажмите, чтобы выбрать',
    loading: 'Загрузка...',
  },

  shareEmailRow: {
    title: 'Дать доступ человеку по e-mail:'
  },

  confirm: {
    title: 'Вы уверены?',
    text: 'Это действие необратимо!',
  },

  tariffBar: {
   day1: 'дней',
   day2: 'дня',
   day3: 'день',
   content: 'Ваш тариф:',
   from: 'из'
  },

  table: {
    add: 'Добавьте столбцы',
  },

  tableTitle: {
    change: <Fragment>изменяйте название <br className="mobile"/>таблицы прямо здесь</Fragment>,
    del: "Удалить таблицу"
  },

  buttonBlockRemove: {
    del: 'Удалить'
  },

  constants: {
    SHARE_TEXT: [
      "Только я",
      "Все",
      "Указать E-mail",
    ],
    SHARE_ACCESS_LIST: {
      reader: 'читатель',
      editor: 'редактор',
    }
  },

  foreignOnActions: {
    tip1: 'Автоматически удаляет или изменяет строки из зависимой таблицы при удалении или изменении связанных строк в главной таблице.',
    tip2: 'При удалении или обновлении связанной строки из главной таблицы устанавливает для столбца внешнего ключа значение NULL',
    tip3: 'Отклоняет удаление или изменение строк в главной таблице при наличии связанных строк в зависимой таблице.',
    tip4: 'При удалении связанной строки из главной таблицы устанавливает для столбца внешнего ключа значение по умолчанию, которое задается с помощью атрибуты DEFAULT. Внимание: несмотря на то, что данная опция в принципе доступна, движок InnoDB не поддерживает данное выражение.',
  },

  verifyCodeField: {
    title: 'Введите код подтверждения',
    subtitle: 'Письмо с кодом отправлено на ваш e-mail',

  },

  resendController: {
    request: <Fragment>запросить повторную отправку <br/>можно через</Fragment>,
    second: 'секунд',
    repeat: 'отправить код повторно',
    limit: 'вы превысили лимит отправок кода',
    text1: <Fragment>если код на почту не приходит, напишите <br/>об этом в чат на главной странице сайта<br/></Fragment>,
    text2: 'указав свой e-mail',
  },

  form: {
    alert: 'Название не может быть пустым!'
  },
}