import { useEffect, useState } from 'react';
import { ReactComponent as ConfirmIcon } from '../../../icons/confirm.svg';
import { ReactComponent as AnchorIcon } from '../../../icons/anchor.svg';
import cssIf from '../../../../../../scripts/helpers/class.add.if';
import { THEME } from '../../../../../../settings/constants';
import ColorPicker from '../../../../colorpicker';
import useGlobal from '../../../../../../store';
import Textfield from '../../../../textfield';
import s from "./color.chooser.module.scss";

const Spectrum = [

  '#F24848',
  '#FF8E3C',
  '#FFC700',
  '#86F815',
  '#3CE8FF',
  '#3C5BFF',
  '#903CFF',
  '#E43CFF'

];

const ColorChooser = ( props ) => {

  const {

    id,
    color, 
    setColor, 
    oldColor,
    name = "",
    isNew, 
    onCancel, 
    onConfirm,
    disablePinchZoom,
    theme,
    wordbook,

  } = props;

  const t = wordbook;

  const [ globalState, globalActions ] = useGlobal();
  const { tableColorGroup } = globalActions;

  const [ selectedColor, setSelectedColor ] = useState( color );
  const [ groupName, setGroupName ] = useState( isNew ? '' : name );

  useEffect(() => { disablePinchZoom() }, []);

  useEffect(() => { 
    
    if ( color === selectedColor ) return;
    setSelectedColor( color ) 
  
  }, [ color ]);

  function handleSetSelectedColor( clr ) {

    setSelectedColor( clr );
    if ( color === clr ) return;
    setColor( selectedColor );

  }
  
  async function handleSave() {

    let groupID;

    if ( isNew ) {

      groupID = await tableColorGroup.add( selectedColor, groupName );

    } else {

      groupID = id;
      tableColorGroup.edit({ id, color: selectedColor, name: groupName });

    }

    setColor( selectedColor );
    onConfirm( groupID );

  }

  function handleCancel() {

    setColor( oldColor );
    onCancel();

  }

  return (

    <div 

      className = {` ${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `}
      onMouseEnter = { disablePinchZoom }

    >

      <div className = { s.header }>

        <AnchorIcon 

          className = { s.cancel }
          onClick = { handleCancel }

        />

        <div className = { s.title }>{ isNew ? t.addGroup : t.changeGroup }</div>

        <ConfirmIcon 

          className = { s.confirm }
          onClick = { handleSave }

        />

      </div>

      <div className = { s.line } />

      <Textfield

        className = { s.input }
        title = { t.tableGroupName }
        value = { isNew ? null : groupName }
        set = { setGroupName }
        placeholder = { t.colorGroupNamePlaceholder }
        maxLength = { 61 }

      />

      <div className = { s.colorBlock }>

        <p className = { s.text }>{ t.headerColor }</p>
        
        <div 
          
          className = { s.color } 
          style = {{ backgroundColor: `${ selectedColor }` }}
          
        />

      </div>

      <ColorPicker

        current = { selectedColor }
        set = { handleSetSelectedColor }
        
      />

      <div className = { s.spectrum }>

        { Spectrum.map( ( item ) => (

          <div 
          
            className = { s.color } 
            style = {{ backgroundColor: `${ item }` }}
            onClick = { () => setSelectedColor( item ) }
          
          />

        ) ) }

      </div>

    </div>

  );

}

export default ColorChooser;