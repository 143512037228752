import { useState } from "react";
import { ReactComponent as TableIcon } from "./icons/table.svg";
import Searchfield from "../../../../textfield/searchfield";
import { WORDBOOK } from "../../../../../../locales";
import useGlobal from "../../../../../../store";
import "./relation.table.selector.scss";

const RelationTableSelector = ({ tables = [], select }) => {

  const [ globalState, globalActions ] = useGlobal();

  const [ search, setSearch ] = useState("");

  const t = WORDBOOK[ globalState.locale ].relationTableSelector;

  return (

    <div className = "reltabsel">

      <div className = "reltabsel__title">{ t.text }</div>

      <Searchfield

        value = { search }
        set = { setSearch }

      />

      <div className = "table__form__liner_x" />

      <div className = "reltabsel__list">

        { tables.length > 0 
          && 
          tables
            .filter( el => el.name.includes( search ) )
            .sort(( a, b ) => a.name.localeCompare( b.name ))
            .map(( el, key ) => (

              <TableInList

                key = { key }
                id = { el.id }
                name = { el.name }
                action = { select }

              />

            ))
        }

      </div>

    </div>

  );

}

export const TableInList = ({ id, name, action = () => {} }) => (

  <div 
  
    onClick = { () => action( id ) }
    className = "reltabsel__list__el flex items-center pointer" 
    
  >

    <TableIcon /> { name }

  </div>

)

export default RelationTableSelector;