import { useEffect, useMemo, useState } from "react";
import ColorCurrent from "./color/color.current";
import ColorChooser from "./color/color.chooser";
import ColorSearch from "./color/color.search";
import Description from "./description";
import { ReactComponent as TipIcon } from '../icons/tip.svg';
import useGlobal from "../../../../store";
import s from "./color.and.description.module.scss";
import cssIf from "../../../../scripts/helpers/class.add.if";
import { THEME } from "../../../../settings/constants";

const MockGroups = [

  {

    color: '#CD800C',
    name: 'Пользователи'

  },

  {

    color: '#1A9CFF',
    name: 'Платёжная система'

  },

  {

    color: '#30E13E',
    name: 'Заказы'

  },

  {

    color: '#4D30E1',
    name: 'Блог'

  },

  {

    color: 'pink',
    name: 'Новости'

  },

  {

    color: 'yellow',
    name: 'Уведомления'

  }

];

const ColorAndDescription = ( props ) => {

  const { 

    wordbook,
    groupID,
    setGroupID,
    color, 
    setColor, 
    description, 
    setDescription, 
    className, 
    isShown,
    setDisablePinchZoom,
    setIsEditingColor

  } = props;

  const WINDOW = {

    SELECTED: 1,
    CHOOSE_GROUP: 2,
    ADD_OR_EDIT_GROUP: 3

  }

  const [ globalState, globalActions ] = useGlobal();
  const { scheme_color_groups, theme } = globalState;

  const [ groups, setGroups ] = useState( MockGroups );
  const [ windowNumber, setWindowNumber ] = useState( WINDOW.SELECTED );
  const [ addNew, setAddNew ] = useState( false );

  const selectedGroup = useMemo(() => {

    return scheme_color_groups.find( el => el.id === groupID );

  }, [ color, windowNumber ]);

  useEffect(() => {

    if ( !isShown ) { setWindowNumber( WINDOW.SELECTED ) }

  }, [ isShown ]);

  useEffect(() => {

    const isEditingColor = windowNumber === WINDOW.ADD_OR_EDIT_GROUP;
    setIsEditingColor( isEditingColor );
    if ( isEditingColor && selectedGroup?.color ) setColor( selectedGroup.color );

  }, [ windowNumber ]);

  const COLOR_GROUP_CONTROLLER = {

    [ WINDOW.SELECTED ]: (

      <ColorCurrent 

        wordbook = { wordbook }

        color = { selectedGroup?.color || color } 
        name = { selectedGroup?.name } 
        onClick = { () => setWindowNumber( WINDOW.CHOOSE_GROUP ) }

        theme = { theme }

      />

    ),

    [ WINDOW.CHOOSE_GROUP ]: (

      <ColorSearch 

        wordbook = { wordbook }

        selected = { selectedGroup?.id }
        groups = { scheme_color_groups }

        onAdd = { () => { setAddNew(true); setWindowNumber( WINDOW.ADD_OR_EDIT_GROUP ) } }
        onEdit = { () => { setAddNew(false); setWindowNumber( WINDOW.ADD_OR_EDIT_GROUP ) } }
        onSelect = { ( groupID ) => { setGroupID( groupID ); setWindowNumber( WINDOW.SELECTED ) }}

        onMouseEnter = { () => setDisablePinchZoom( true ) }

        theme = { theme }

      />

    ),

    [ WINDOW.ADD_OR_EDIT_GROUP ]: (

      <ColorChooser
          
        wordbook = { wordbook }

        id = { selectedGroup?.id || '' }
        name = { selectedGroup?.name || '' }

        color = { color }
        setColor = { setColor }
        oldColor = { selectedGroup?.color }

        groups = { groups }
        setGroups = { setGroups }

        isNew = { addNew }
        onCancel = { () => setWindowNumber( WINDOW.CHOOSE_GROUP ) }
        onConfirm = { ( groupID ) => { setGroupID( groupID ); setWindowNumber( WINDOW.SELECTED ) }}

        disablePinchZoom = { () => setDisablePinchZoom( true ) }

        theme = { theme }

      />

    ),

  }

  const showDescription = ( windowNumber !== WINDOW.CHOOSE_GROUP && windowNumber !== WINDOW.ADD_OR_EDIT_GROUP );

  return (

    <div 
    
      className = {` ${ s.container } ${ className } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) }`}
      onTouchStart = { () => setDisablePinchZoom( true ) }
      onTouchEnd = { () => setDisablePinchZoom( false ) }
      
    >

      { COLOR_GROUP_CONTROLLER[ windowNumber ] }

      { showDescription && 
        
        <Description 

          description = { description } 
          setDescription = { setDescription } 

          theme = { theme }
          wordbook = { wordbook }

        />

      }

      <TipIcon className = {` ${ s.icon } ${ cssIf( theme === THEME.LIGHT, s.iconLight ) } `}/>

    </div>

  );

}

export default ColorAndDescription;