import React, { useState, useEffect } from 'react';
import { RgbaColorPicker } from "react-colorful";
import { hexToRgb, parseRGBA } from "./colorpicker.helpers";
import "./colorpicker.scss";

const ColorPicker = ( props ) => {

  const { current, set, style, onTouchUp = () => {} } = props;

  useEffect(()=>{ console.log(`uef picker propsColor ${typeof current}`, current) },[])

  const initialColor = { r: 255, g: 255, b: 255, a: 1 };
  const [ color, setColor ] = useState( rgbaFromString( current ) || { r: 200, g: 150, b: 35, a: 1 } );
  
  function rgbaFromString( current ) {

    let colors, result;

    if ( typeof current === "object" ) {

      if ( current === null ) {

        set(`rgba(${ initialColor.r }, ${ initialColor.g }, ${ initialColor.b }, ${ initialColor.a })`);
        return initialColor;

      }

      result = {

        r: current[0],
        g: current[1],
        b: current[2],
        a: current.length === 4 ? current[3] : 1
  
      }

    } else if ( current.substr( 0, 4 ) === "rgba" ) {

      result = parseRGBA( current )
      
    } else if ( current.substr( 0, 1 ) === "#" ) {

      colors = hexToRgb( current );
      
      result = {

        r: colors.r,
        g: colors.g,
        b: colors.b,
        a: 1
  
      }

    } else {

      return false;

    }

    // console.log(`debug rgbFromString result:`, colors );

    return result;

  }

  useEffect(() => {

    setColor( rgbaFromString( current ) || { r: 200, g: 150, b: 35, a: 1 } );

  }, [ current ]);

  return (

    <div className = "colorPicker" style = { style }>

      <div 
        className="colorPlaceholder"
      >

        <div 
          className="colorPicker"
          onMouseUp={(e) => {
              
            // console.log('onMouseUp');
            onTouchUp();
            ////e.stopPropagation()
          
          }}

          onMouseOut={(e) => {
            
            // console.log('onMouseOut');
            onTouchUp();
            ////e.stopPropagation()
          
          }}

          onKeyUp={(e) => {
            
            // console.log('onKeyUp');
            onTouchUp();
            ////e.stopPropagation()
          
          }}

          onTouchEnd={(e) => {
            
            // console.log('onTouchEnd');
            onTouchUp();
            ////e.stopPropagation()
          
          }}

          onTouchUp={(e) => {
            
            // console.log('onTouchUp');
            onTouchUp();
            ////e.stopPropagation()
          
          }}
        >

          <RgbaColorPicker 
          
            color = { color } 
            onChange = { ( color ) => {
              
              // console.log(`RBGColorPicker`, JSON.stringify(color));
              setColor( color );
              set(`rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`);
              
            }} 
            
          />

        </div>

      </div>

    </div>

  );

}

export default ColorPicker;