import React, {Fragment} from "react";
import { TARIFF_PRICE } from "../settings/constants";

export const zh = {

  currentLanguage: "简体中文",
  quickHelpImageUrl: "/images/quickguide/zh.jpg",

  signin: {
    email: '电子邮件',
    no_profile: '我没有帐户',
    signup: '注册',
    enter: '登录',
  },

  signup: {
    email: '电子邮件',
    signup: '注册',
    auth: '登录',
    hasAccount: '我已经有帐户',
    phone:
      <Fragment>
        电话
        <span style={{ opacity: 0.6, paddingLeft: '2px' }}>
          （可选）
        </span>
      </Fragment>,
    password: '密码',
    passwordPlaceholder: '输入密码',
    passwordRepeat: '重复密码',
    passwordRepeatPlaceholder: '重复密码',
    offerSTART: '我接受',
    offer1: '报价协议的条款',
    offer2: '个人数据处理政策',
    offer3: '并同意处理我的个人数据',
    bad_pass: '密码不匹配！',
  },

  tariff: {
    p1: <Fragment>充分利用 <br className='mobile' />数据库设计 <br className='mobile' />！</Fragment>,
    p2: <Fragment>支持开发者， <br className='mobile' />为项目的发展贡献力量</Fragment>,
    p3: '数据库架构构建器的愉快直观界面',
    p4: '与其他选项相比，最可视化的数据库架构视图',
    p5: '与您的团队和客户共享可视化数据库架构',
    p6: '将可视化架构导出为 SQL 转储（包括关系和索引）',
    p7: '创建无限项目',
    p8: '工作需要从任何设备访问互联网',
    p9: '订阅结束后没有自动付款',
    p10: '支持项目！',
    p11: '对作者表示一些尊重',
    priceMonth: `费用：± ${TARIFF_PRICE.MONTH.DOLLAR } $每月`,
    priceDayTariffMonth: <Fragment>这仅仅是 ± <b>{ ( TARIFF_PRICE.MONTH.DOLLAR / 30 ).toFixed( 2 ) }</b> $每天！</Fragment>,
    priceDayTariffYear: <Fragment>这仅仅是 ± <b>{ ( TARIFF_PRICE.YEAR.DOLLAR / 365 ).toFixed( 2 ) }</b> $每天！</Fragment>,
    payMonth: `支付 1 个月：± ${TARIFF_PRICE.MONTH.DOLLAR } $`,
    priceYear: `仅 ±${( TARIFF_PRICE.YEAR.DOLLAR / 12 ).toFixed( 2 )}$/每月支付年费！`,
    payYear: `支付一年：± ${TARIFF_PRICE.YEAR.DOLLAR } $`,
    p12: '您的数据库项目从未如此美观！',
  },

  schemes: {
    alert: `在演示模式下，您只能创建一个可视化架构。如果您喜欢构建器，您可以明天支付每月仅 ${TARIFF_PRICE.MONTH.DOLLAR }$，这只是出租车的费用！`,
    title: '我的架构',
    btnCreate: '创建',
    btnCancel: '取消',
    noSchemes: '您还没有创建任何架构',
    available: '对我可用',
  },

  addSchemes: {
    alert: '名称不能为空！',
    title: '新架构',
    name: '名称：',
    namePlaceholder: '用英语',
    description: '描述：',
    descriptionPlaceholder: '可选',
    add: '添加',
  },

  payment: {
    title: '支付成功！',
    subtitle: '您的套餐有效期至',
    thanking: '享受使用！',
    btnEnter: '开始使用',
    cancelPayment: '支付已取消',
    cancelPaymentThanking: '您已中断套餐支付',
    cancelPaymentSubtitle: '如需技术支持，请在聊天中联系',
    cancelPaymentRepeat: '再试一次！',
    return: '返回套餐',
  },

  constructor: {
    dublicateTable: '创建该表的副本',
    deleteTable: name => <Fragment>您想删除表 {name} 吗？<br />此操作是不可逆的！</Fragment>,
    deleteTableColumn: (t, c) => <Fragment>您想从表 {t} 中删除列 {c} 吗？<br />此操作是不可逆的！</Fragment>,
    btnNewTable: '添加表',
    textNewTable: <Fragment>开始设计 <br className="mobile" />数据库架构 - 添加 <br className="mobile" />您的第一张表！</Fragment>,
    noTable: "这个架构中还没有表",
    title:
      <Fragment>
        我希望使数据库设计更好，更方便，更有用！
      </Fragment>,
    text:
      <Fragment>
        而只有您能帮助我做到这一点！在此消息下方的聊天中写<br />
        你喜欢和不喜欢的，哪些方便或不方便，缺少什么功能 <br />
        是什么。为了使这个项目尽可能有用，应该添加什么？
      </Fragment>,
    subtitle:
      <Fragment>
        您的意见对我非常重要！ <span>安德烈·里基，项目创建者。</span>
      </Fragment>,
  },

  sidePanelTools: {
    showTableGroups: '显示表组',
    colorGroupNameEmpty: '未命名',
  },

  columnSettings: {
    lengthSymbols: '字符长度：',
    lengthBytes: '字节长度：',
    lengthNumeric: '数字个数：',
    lengthSigns: '允许的以逗号分隔的值：',

    name: '名称：',
    namePlaceholder: '用英语且没有空格',

    checkboxTipNotNull: '不能为空',
    checkboxTipUnique: '值必须是唯一的',
    checkboxTipPrimaryKey: '主键',
    checkboxTipAutoIncrement: '自动递增',

    selectForeign: '与另一个表的关系：',
    selectOnDelete: '删除时：',
    selectOnUpdate: '更新时：',
    selectSetType: '数据类型：',
    textDecimal: '小数部分：',
    textDefaultValue: '默认值：',
    textDefaultValuePlaceholder: '默认值：',
    textComment: '评论：',
    textCommentPlaceholder: '可选',
    textUnique: '此列自动建索引，因为它被标记为 UNIQUE',
    textUniqueTitle: '索引显著加速搜索此列的值',

    checkboxIsIndexTitle: '对这一列建立索引',
    checkboxIsIndexTip: '在一个将被搜索的表字段上创建索引。这样显著加快搜索时间。始终对参与表连接的字段使用 INNER JOIN、LEFT JOIN、RIGHT JOIN 建立索引。由于索引占用空间，仅对会查询的字段建立索引。',

    selectIndexMethod: '索引方法：',
    selectIndexType: '索引类型：',

    textareaComment: '评论：',
    textareaCommentPlaceholder: '可选',
  },

  colorAndDescription: {
    description: '表的简要描述：',
    placeholder: '可选',
    colorGroupNamePlaceholder: '例如，用户',
    colorGroupNameEmpty: '未命名',
    addNewGroup: '添加新组',
    tableGroup: '表组：',
    pushToChoose: '(点击选择)',
    chooseTableColor: '选择表头颜色',
    addGroup: '添加组',
    changeGroup: '更改组',
    tableGroupName: '表组名称：',
    headerColor: '表组标题的颜色：',
    editColorGroupTip: '更改组名或颜色',
    showAllTables: '显示所有表',
  },

  indexEnums: {
    INDEX_TYPES: {
      NORMAL: `标准索引按值。允许快速检索与某些特定值或范围匹配的行。`,
      FULLTEXT: `全文索引。仅适合 *TEXT 和 VARCHAR 数据类型。用于在列的文本内容中搜索关键字或短语。`,
      SPATIAL: `空间数据索引。仅适合几何数据类型。用于有效搜索地理对象并执行查找最近对象或定义落在特定范围内的区域等操作。`,
    },
    INDEX_METHODS: {
      BTREE: `最常见的 MySQL 索引方法，支持按值的有效搜索。它使用平衡树结构以排序的方式存储键。`,
      HASH: `哈希 - 该方法使用哈希函数计算键的哈希值并创建哈希表以快速搜索。然而，基于哈希的索引不支持范围查询。`,
      GIN: `GIN（广义倒排索引） - 这种索引方法广泛用于全文搜索和在 JSON 或数组中搜索数据数组。`,
    }
  },

  dataTypes: {
    DATA_TYPES_TIPS: {
      i0: `存储整数，范围从 -2,147,483,648 到 2,147,483,647，或无符号数从 0 到 4,294,967,295。`,
      i15: `存储整数，范围从 -128 到 127，或无符号数从 0 到 255。`,
      i16: `存储整数，范围从 -32,768 到 32,767，或无符号数从 0 到 65,535。`,
      i17: `存储整数，范围从 -8,388,608 到 8,388,607，或无符号数从 0 到 16,777,215。`,
      i18: `存储整数，范围从 -9,223,372,036,854,775,808 到 9,223,372,036,854,775,807，或无符号数从 0 到 18,446,744,073,709,551,615。`,
      i10: `存储浮点数，范围从 -3.402823466E+38 到 -1.175494351E-38，0，以及从 1.175494351E-38 到 3.402823466E+38。FLOAT 中数字的精度可能因其规模而异。通常，FLOAT 的精度约为 7 位数字。然而，要记住，FLOAT 数据类型表示浮点数的近似表示，并可能存在精度损失。如果精度对您的数据至关重要，使用具有固定精度和比例的 DECIMAL 数据类型可能更好。请注意，超出 FLOAT 数字范围可能导致舍入错误，因此建议仔细计算必要的限制并在将数据保存到 FLOAT 字段之前检查。`,
      i19: `存储浮点数，范围从 -1.7976931348623157E+308 到 -2.2250738585072014E-308，0，以及从 2.2250738585072014E-308 到 1.7976931348623157E+308。DOUBLE 数据类型是浮点数的近似表示，并在某些情况下可能会失去精度。因此，为了实现高精度而不丢失数据，使用具有固定精度和比例的 DECIMAL 数据类型可能更好。`,
      i20: `存储数字（包括小数），具有固定的精度和比例。以十进制格式表示数字，其中精度由数字中的位数决定，比例是小数点后的位数。与 FLOAT 和 DOUBLE 类型不同，DECIMAL 数据类型提供无损存储数字的功能。需要注意的是，DECIMAL 数据类型需要比 FLOAT 和 DOUBLE 数据类型更多的内存以存储数据。此外，在对 DECIMAL 数字进行数学操作时，应注意可能的溢出和四舍五入结果的需要。`,
      i1: `可变长度字符串，最多长度为 65,535 个字符。创建时指定的确切长度会存储在内存中。VARCHAR 占用的空间比 CHAR 少，但会受到碎片化的影响，因此可能会在数据处理时降低速度。可以使用 varchar 字段创建索引，包括主键索引。对于文本，索引有限，并且对索引文本列的操作可能受到限制或无法使用。`,
      i2: `可变长度字符串，最多长度为 65,535 个字符。被视为字符字符串。它精确存储字符集，并且基于字符集排序和比较值。与 varchar 不同，文本在单独的块中存储数据，而不是在字符串本身中，这可能会影响读写操作期间的性能。`,
      i21: `固定长度字符串，最多长度为 255 个字符。如果插入记录的长度较短，MySQL 会自动用空格填充值。`,
      i6: `可变长度字符串，最多长度为 255 个字符。被视为字符字符串。它精确存储字符集，并且基于字符集排序和比较值。与 varchar 不同，文本在单独的块中存储数据，而不是在字符串本身中，这可能会影响读写操作期间的性能。`,
      i7: `可变长度字符串，最多长度为 16,777,215 个字符。被视为字符字符串。它精确存储字符集，并且基于字符集排序和比较值。与 varchar 不同，文本在单独的块中存储数据，而不是在字符串本身中，这可能会影响读写操作期间的性能。`,
      i8: `可变长度字符串，最多长度为 4,294,967,295 个字符。被视为字符字符串。它精确存储字符集，并且基于字符集排序和比较值。与 varchar 不同，文本在单独的块中存储数据，而不是在字符串本身中，这可能会影响读写操作期间的性能。`,
      i28: `固定长度的二进制数据。`,
      i29: `可变长度的二进制数据。`,
      i12: `最多 65,535 字节（64 Kb）的二进制数据。可以存储任何文件或多媒体内容。排序和比较操作基于字节的数值。`,
      i11: `最多 255 字节的二进制数据。可以存储任何文件或多媒体内容。排序和比较操作基于字节的数值。`,
      i13: `最多 16,777,215 字节（16 Mb）的二进制数据。可以存储任何文件或多媒体内容。排序和比较操作基于字节的数值。`,
      i14: `最多 4,294,967,295 字节（4 Gb）的二进制数据。可以存储任何文件或多媒体内容。排序和比较操作基于字节的数值。`,
      i22: `仅日期，格式为 YYYY-MM-DD。允许的值范围从 1000-01-01 到 9999-12-31。`,
      i3: `日期和时间，格式为 YYYY-MM-DD HH:MM:SS。允许的值范围从 1000-01-01 00:00:00 到 9999-12-31 23:59:59。`,
      i4: `日期和时间。以从 1970 年 1 月 1 日格林威治标准时间起经过的秒数存储。占用的空间是 DATETIME 类型的一半。然而，范围限制为 1970-01-01 00:00:01 到 2038-01-09 03:14:07。`,
      i23: `仅时间，格式为 HH:MM:SS。允许的值范围从 00:00:00 到 23:59:59。`,
      i24: `仅年份，格式为 YYYY 或 YY。允许的值范围从 1901 到 2155 或从 70 到 69（1970 — 2069）。`,
      i25: `仅存储一组允许值中的一个值，占用 1-2 字节。`,
      i26: `存储来自允许值集合的最多 64 个值，占用 1 到 8 字节。`,
      i30: `存储任何类型几何的值。其他单值类型（POINT、LINESTRING 和 POLYGON）限制它们的值为特定几何类型。`,
      i31: `用于存储二维地理坐标 - 纬度和经度。`,
      i32: `用于存储由一系列点组成的线的几何。代表一组由直线段连接的有序点。`,
      i33: `用于存储和处理地理多边形。多边形是在平面上由连接顶点的线构成的封闭图形。当需要存储有关地理对象的信息时，例如领土边界、兴趣点或地块形状，此数据类型非常有用。`,
      i35: `存储一组二维地理坐标 - 纬度和经度。`,
      i37: `存储一组地理多边形。多边形是在平面上由连接顶点的线构成的封闭图形。当需要存储有关地理对象的信息时，例如领土边界、兴趣点或地块形状，此数据类型非常有用。`,
      i36: `存储一组由一系列点组成的线的几何。代表一组由直线段连接的有序点。`,
      i34: `存储任何类型对象的集合。其他集合类型（MULTIPOINT、MULTILINESTRING、MULTIPOLYGON 和 GEOMETRYCOLLECTION）限制集合$素必须具有特定的几何类型。`,
      i5: `存储布尔值 "真" 或 "假"（true/false）。`,
      i9: `以 JSON 格式存储数据。`,
      i27: `UUID 数据类型确保识别符的唯一性，即使在分布式系统中，并且在集成多个数据源或并行处理时避免识别符的冲突。请注意，MySQL 没有内置 UUID 数据类型（与 PostgreSQL 和 MongoDB 不同），但可以在后端生成 UUID 并将其存储为 VARCHAR 或 CHAR。`,
    },

    DATA_TYPES_GROUPS: {
      NUMBER: '数字',
      DECIMAL: '十进制',
      TEXT: '文本',
      BINARY: '二进制',
      DATETIME: '日期/时间',
      DATETIME_TIP: `允许处理日期和时间的数据类型。`,
      CUSTOM: '组合',
      CUSTOM_TIP: '`字符串数据类型，仅能存储预定义列表中的值。尽管值列表由字符串组成，但仅在表中存储与可能值关联的数字。因此，它们占用很少的空间。`',
      SPATIAL: '几何',
      OTHER: '其他',
    }
  },

  toggleMouse: {
    mouse: '如果您使用鼠标而不是触控板，请选择',
    touchpad: '如果您使用触控板而不是鼠标，请选择',
  },

  header: {
    USER_MENU: {
      out: '退出',
    },
    toList: '到架构列表',
    helpIcon: '简要说明',
    signin: '登录',
    register: '注册',
    popup: '您确定要删除该项目及其所有表吗？此操作是不可逆的！',
  },

  relationSelector: {
    no_selected: "未选择",
    err: 'getTableNameAndColumns 未能找到 ID 的表',
    err_not_found: '错误：未找到',
    del_rel: '删除关系',
  },

  shareToEmails: {
    text: '您已经授予对该项目的访问权限：',
  },

  popupConfirm: {
    text: '确认',
  },

  relationColumnSelector: {
    title: '与表的关系：',
    chooseOtherTable: '选择其他',
    reltabsel__title: '引用列：',
  },

  popupShare: {
    title: '共享设置',
    subtitle: '谁可以查看此项目？',
    url: '公共链接：',
    confirmText: '保存',
  },

  schemeName: {
    text1: '保存 SQL 转储',
    t2: '共享设置',
    t3: '删除项目',
    alert: '名称不能为空！',
  },

  row: {
    index: '索引',
    primarykey: '主键',
    foreignkey: '外键',
    key: '键',
    delete_col: '删除列',
  },

  toggleTheme: {
    dark: '黑暗主题',
    light: '明亮主题',
  },

  radioSelector: {
    text: '未选择',
  },

  searchfield: {
    text: '搜索',
  },

  relationTableSelector: {
    text: '选择表：',
  },

  messageFromRikky: {
    close1: '稍后提醒我',
    close2: '我已经写过',
  },

  loading: {
    text: '请稍候，数据正在加载中',
  },

  selectfield: {
    placeholder: '点击选择',
    loading: '加载中...',
  },

  shareEmailRow: {
    title: '通过电子邮件授予某人访问权限：',
  },

  confirm: {
    title: '您确定吗？',
    text: '此操作是不可逆的！',
  },

  tariffBar: {
    day1: '天',
    day2: '天',
    day3: '天',
    content: '您的套餐：',
    from: '来自',
  },

  table: {
    add: '添加列',
  },

  tableTitle: {
    change: <Fragment>在这里更改 <br className="mobile" />表的名称</Fragment>,
    del: "删除表",
  },

  buttonBlockRemove: {
    del: '删除'
  },

  constants: {
    SHARE_TEXT: [
      "仅我",
      "每个人",
      "输入电子邮件",
    ],
    SHARE_ACCESS_LIST: {
      reader: '阅读者',
      editor: '编辑者',
    }
  },

  foreignOnActions: {
    tip1: '在删除或修改主表中相关行时，自动删除或修改依赖表中的行。',
    tip2: '从主表删除或更新相关行时，将外键列设置为 NULL',
    tip3: '如果在依赖表中存在相关行，则拒绝在主表中删除或修改行。',
    tip4: '从主表删除相关行时，将外键列设置为使用 DEFAULT 属性定义的默认值。注意：尽管可以使用此选项，但 InnoDB 引擎不支持此表达式。',
  },

  verifyCodeField: {
    title: '输入验证码',
    subtitle: '带有验证码的信函已发送到您的电子邮件',
  },

  resendController: {
    request: <Fragment>您可以通过 <br />请求重发</Fragment>,
    second: '秒',
    repeat: '重发验证码',
    limit: '您已超出发送验证码的限制',
    text1: <Fragment>如果代码没有到达您的邮箱，请<br />在网站首页的聊天中告知<br /></Fragment>,
    text2: '指定您的电子邮件',
  },

  form: {
    alert: '名称不能为空！',
  },
}