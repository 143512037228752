import React, {Fragment} from "react";
import { TARIFF_PRICE } from "../settings/constants";

export const eng = {

  currentLanguage: "English",
  quickHelpImageUrl: "/images/quickguide/eng.jpg",

  signin: {
    email: 'Email',
    no_profile: 'I don\'t have an account',
    signup: 'Sign Up',
    enter: 'Sign In',
  },

  signup: {
    email: 'Email',
    signup: 'Sign Up',
    auth: 'Log In',
    hasAccount: 'I already have an account',
    phone:
      <Fragment>
        Phone
        <span style={{ opacity: 0.6, paddingLeft: '2px' }}>
          (optional)
        </span>
      </Fragment>,
    password: 'Password',
    passwordPlaceholder: 'Enter password',
    passwordRepeat: 'Repeat password',
    passwordRepeatPlaceholder: 'Repeat password',
    offerSTART: 'I accept the terms of',
    offer1: 'the Offer Agreement',
    offer2: 'the Personal Data Processing Policy',
    offer3: 'and consent to the processing of my personal data',
    bad_pass: 'Passwords do not match!',
  },

  tariff: {
    p1: <Fragment>Use <br className='mobile' />Database Design <br className='mobile' />to the fullest!</Fragment>,
    p2: <Fragment>Support the developer, <br className='mobile' />and contribute to the project’s development</Fragment>,
    p3: 'A pleasant intuitive interface of the database schema builder',
    p4: 'The most visual view of the database schema compared to the alternatives',
    p5: 'Share visual database schemas with your team and clients',
    p6: 'Export visual schemas into SQL dumps (with relationships and indexes)',
    p7: 'Create unlimited projects',
    p8: 'Work requires internet access from any device',
    p9: 'No auto-payments after the subscription ends',
    p10: 'Support the project!',
    p11: 'Show some respect to the author',
    priceMonth: `Cost: ± ${TARIFF_PRICE.MONTH.DOLLAR } $ per month`,
    priceDayTariffMonth: <Fragment>That’s only ± <b>{ ( TARIFF_PRICE.MONTH.DOLLAR / 30 ).toFixed( 2 ) }</b> $ a day!</Fragment>,
    priceDayTariffYear: <Fragment>That’s only ± <b>{ ( TARIFF_PRICE.YEAR.DOLLAR / 365 ).toFixed( 2 ) }</b> $ a day!</Fragment>,
    payMonth: `Pay for 1 month: ± ${TARIFF_PRICE.MONTH.DOLLAR } $`,
    priceYear: `Only ±${( TARIFF_PRICE.YEAR.DOLLAR / 12 ).toFixed( 2 )}$/month when paid annually!`,
    payYear: `Pay for a year: ± ${TARIFF_PRICE.YEAR.DOLLAR } $`,
    p12: 'Your database projects have never looked this good!',
  },

  schemes: {
    alert: `In demo mode, you can create only one visual schema. If you like the builder, you can pay for it tomorrow for just ${TARIFF_PRICE.MONTH.DOLLAR }$ per month, which is the cost of a taxi!`,
    title: 'My Schemas',
    btnCreate: 'Create',
    btnCancel: 'Cancel',
    noSchemes: 'You haven\'t created any schemas yet',
    available: 'Available to me',
  },

  addSchemes: {
    alert: 'The name cannot be empty!',
    title: 'New Schema',
    name: 'Name:',
    namePlaceholder: 'In English',
    description: 'Description:',
    descriptionPlaceholder: 'Optional',
    add: 'Add',
  },

  payment: {
    title: 'Successfully Paid!',
    subtitle: 'Your tariff will be valid until',
    thanking: 'Enjoy using!',
    btnEnter: 'Start Using',
    cancelPayment: 'Payment Cancelled',
    cancelPaymentThanking: 'You have interrupted the payment of the tariff',
    cancelPaymentSubtitle: 'For technical support, please write in the chat',
    cancelPaymentRepeat: 'Try again!',
    return: 'Return to tariffs',
  },

  constructor: {
    dublicateTable: 'Create a copy of this table',
    deleteTable: name => <Fragment>Do you want to delete the table {name}?<br />This action is irreversible!</Fragment>,
    deleteTableColumn: (t, c) => <Fragment>Do you want to delete column {c} from table {t}?<br />This action is irreversible!</Fragment>,
    btnNewTable: 'Add Table',
    textNewTable: <Fragment>Start designing <br className="mobile" />the database schema - add <br className="mobile" />your first table!</Fragment>,
    noTable: "There are no tables in this schema yet",
    title:
      <Fragment>
        I want to make Database Design better, more convenient, and more useful for you!
      </Fragment>,
    text:
      <Fragment>
        And only you can help me do this! Write in the chat below this message,<br />
        what you like and dislike, what is convenient or inconvenient, what functionality <br />
        is lacking. What should be added to make this project as useful as possible for you?
      </Fragment>,
    subtitle:
      <Fragment>
        Your opinion is very important to me! <span>Andrey Rikky, project creator.</span>
      </Fragment>,
  },

  sidePanelTools: {
    showTableGroups: 'Show table groups',
    colorGroupNameEmpty: 'Unnamed',
  },

  columnSettings: {
    lengthSymbols: 'Length in symbols:',
    lengthBytes: 'Length in bytes:',
    lengthNumeric: 'Number of digits:',
    lengthSigns: 'Allowed values separated by commas:',

    name: 'Name:',
    namePlaceholder: 'In English without spaces',

    checkboxTipNotNull: 'Cannot be empty',
    checkboxTipUnique: 'Value must be unique',
    checkboxTipPrimaryKey: 'Primary key',
    checkboxTipAutoIncrement: 'Auto-increment',

    selectForeign: 'Relationship with another table:',
    selectOnDelete: 'On delete:',
    selectOnUpdate: 'On update:',
    selectSetType: 'Data type:',
    textDecimal: 'Decimal part:',
    textDefaultValue: 'Default value:',
    textDefaultValuePlaceholder: 'Default value:',
    textComment: 'Comment:',
    textCommentPlaceholder: 'Optional',
    textUnique: 'This column is automatically indexed, as it is marked UNIQUE',
    textUniqueTitle: 'Index speeds up searching for values in this column significantly',

    checkboxIsIndexTitle: 'Index this column',
    checkboxIsIndexTip: 'An index is created on a field in the table that will be searched. This speeds up search times significantly. Always create indexes for fields involved in table joins using INNER JOIN, LEFT JOIN, RIGHT JOIN. Since indexes take up space, only index fields that will be queried.',

    selectIndexMethod: 'Index method:',
    selectIndexType: 'Index type:',

    textareaComment: 'Comment:',
    textareaCommentPlaceholder: 'Optional',
  },

  colorAndDescription: {
    description: 'Brief description of the table:',
    placeholder: 'Optional',
    colorGroupNamePlaceholder: 'For example, Users',
    colorGroupNameEmpty: 'Unnamed',
    addNewGroup: 'Add New Group',
    tableGroup: 'Table Group:',
    pushToChoose: '(click to select)',
    chooseTableColor: 'Choose table header color',
    addGroup: 'Adding group',
    changeGroup: 'Changing group',
    tableGroupName: 'Table group name:',
    headerColor: 'Color of the table group headers:',
    editColorGroupTip: 'Change the group name or color',
    showAllTables: 'Show all tables',
  },

  indexEnums: {
    INDEX_TYPES: {
      NORMAL: `Standard index by value. Allows for quick retrieval of rows that match certain values or ranges of values.`,
      FULLTEXT: `Full-text index. Suitable only for *TEXT and VARCHAR data types. Used for searching keywords or phrases in the text content of a column.`,
      SPATIAL: `Spatial data index. Suitable only for geometric data types. Used for efficiently searching geographic objects and performing operations such as finding nearest objects or defining areas that fall within certain bounds.`,
    },
    INDEX_METHODS: {
      BTREE: `The most common indexing method in MySQL that supports efficient searching by values. It stores keys in sorted order using a balanced tree structure.`,
      HASH: `Hashing - this method uses hash functions to compute hash values for keys and create a hash table for quick searches. However, hash-based indexes do not support range queries.`,
      GIN: `GIN (Generalized Inverted Index) - this indexing method is widely used for full-text search and searching arrays of data in JSON or arrays.`,
    }
  },

  dataTypes: {
    DATA_TYPES_TIPS: {
      i0: `Stores integer numbers, without decimal parts in the range from -2,147,483,648 to 2,147,483,647, or unsigned numbers from 0 to 4,294,967,295.`,
      i15: `Stores integer numbers, without decimal parts in the range from -128 to 127, or unsigned numbers from 0 to 255.`,
      i16: `Stores integer numbers, without decimal parts in the range from -32,768 to 32,767, or unsigned numbers from 0 to 65,535.`,
      i17: `Stores integer numbers, without decimal parts in the range from -8,388,608 to 8,388,607, or unsigned numbers from 0 to 16,777,215.`,
      i18: `Stores integer numbers, without decimal parts in the range from -9,223,372,036,854,775,808 to 9,223,372,036,854,775,807, or unsigned numbers from 0 to 18,446,744,073,709,551,615.`,
      i10: `Stores floating-point numbers in the range from -3.402823466E+38 to -1.175494351E-38, 0, and from 1.175494351E-38 to 3.402823466E+38. The precision of numbers in FLOAT may vary depending on their scale. Typically, the precision of FLOAT is about 7 digits. However, it is important to remember that the FLOAT data type represents an approximate representation of floating-point numbers and may have a loss of precision. If precision is crucial for your data, it might be better to use the DECIMAL data type, which provides exact storage of numbers with fixed precision and scale. It is important to note that exceeding the range of numbers in FLOAT can lead to rounding errors, so it is recommended to carefully compute necessary limits and check data before saving it in a FLOAT field.`,
      i19: `Stores floating-point numbers in the range from -1.7976931348623157E+308 to -2.2250738585072014E-308, 0, and from 2.2250738585072014E-308 to 1.7976931348623157E+308. The DOUBLE data type is an approximate representation of floating-point numbers and may lose precision in some cases. Therefore, for high accuracy without data loss, it may be better to use the DECIMAL data type, which provides exact storage of numbers with fixed precision and scale.`,
      i20: `Stores a number (including fractions), with fixed precision and scale. Represents numbers in decimal format, where precision is determined by the number of digits in the number, and scale is the number of digits after the decimal point. The DECIMAL data type, unlike FLOAT and DOUBLE types, provides storage of numbers without loss of precision. It should be noted that the DECIMAL data type requires more memory to store data compared to FLOAT and DOUBLE types. Additionally, when performing mathematical operations on DECIMAL numbers, attention should be paid to possible overflow and the need to round results.`,
      i1: `Variable-length string up to 65,535 characters. The exact length specified during creation is stored in memory. VARCHAR takes up less space than CHAR but is subject to fragmentation and may therefore lose speed in data processing. The varchar field can be used to create indexes, including the index on the primary key. With text, indexing is limited, and operations on indexed text columns may be restricted or unavailable.`,
      i2: `Variable-length string up to 65,535 characters. Treated as a character string. It stores exactly the character set, and values are sorted and compared based on the character set collation. Unlike varchar, text stores data in a separate block rather than in the string itself, which may affect performance during read and write operations.`,
      i21: `Fixed-length string up to 255 characters. If the inserted record's length is less, MySQL automatically pads the value with spaces.`,
      i6: `Variable-length string up to 255 characters. Treated as a character string. It stores exactly the character set, and values are sorted and compared based on the character set collation. Unlike varchar, text stores data in a separate block rather than in the string itself, which may affect performance during read and write operations.`,
      i7: `Variable-length string up to 16,777,215 characters. Treated as a character string. It stores exactly the character set, and values are sorted and compared based on the character set collation. Unlike varchar, text stores data in a separate block instead of in the string itself, which could impact performance during read and write operations.`,
      i8: `Variable-length string up to 4,294,967,295 characters. Treated as a character string. It stores exactly the character set, and values are sorted and compared based on the character set collation. Unlike varchar, text stores data in a separate block rather than in the string itself, which may affect performance during reading and writing operations.`,
      i28: `Fixed-length binary data.`,
      i29: `Variable-length binary data.`,
      i12: `Binary data up to 65,535 bytes (64 Kb). Can store any files or multimedia content. Sorting and comparison operations are based on the numeric values of bytes.`,
      i11: `Binary data up to 255 bytes. Can store any files or multimedia content. Sorting and comparison operations are based on the numeric values of bytes.`,
      i13: `Binary data up to 16,777,215 bytes (16 Mb). Can store any files or multimedia content. Sorting and comparison operations are based on the numeric values of bytes.`,
      i14: `Binary data up to 4,294,967,295 bytes (4 Gb). Can store any files or multimedia content. Sorting and comparison operations are based on the numeric values of bytes.`,
      i22: `Only date in the format YYYY-MM-DD. Allowed values from 1000-01-01 to 9999-12-31.`,
      i3: `Date and time in the format YYYY-MM-DD HH:MM:SS. Allowed values from 1000-01-01 00:00:00 to 9999-12-31 23:59:59.`,
      i4: `Date and time. Stored as the number of seconds that have elapsed since January 1, 1970, Greenwich Mean Time. Takes up half as much space as the DATETIME type. However, the range is limited to values from 1970-01-01 00:00:01 to 2038-01-09 03:14:07.`,
      i23: `Only time in the format HH:MM:SS. Allowed values from 00:00:00 to 23:59:59.`,
      i24: `Only year in format YYYY or YY. Allowed values from 1901 to 2155 or from 70 to 69 (1970 — 2069).`,
      i25: `Stores only one value from a set of allowed values, occupying 1-2 bytes.`,
      i26: `Stores up to 64 simultaneous values from a set of allowed values, occupying from 1 to 8 bytes.`,
      i30: `Stores values of geometry of any type. Other single-value types (POINT, LINESTRING, and POLYGON) restrict their values to specific types of geometry.`,
      i31: `Used to store two-dimensional geographic coordinates - latitude and longitude.`,
      i32: `Used to store the geometry of a line consisting of a sequence of points. Represents a set of ordered points connected by straight line segments.`,
      i33: `Used to store and work with geographic polygons. A polygon is a closed figure on a plane bounded by lines connecting the vertices. This data type is useful when you need to store information about geographic objects, such as borders of territories, points of interest, or shapes of land parcels.`,
      i35: `Stores a collection of two-dimensional geographic coordinates - latitude and longitude.`,
      i37: `Stores a collection of geographic polygons. A polygon is a closed figure on a plane bounded by lines connecting the vertices. This data type is useful when you need to store information about geographic objects, such as borders of territories, points of interest, or shapes of land parcels.`,
      i36: `Stores a collection of the geometry of a line consisting of a sequence of points. Represents a set of ordered points connected by straight line segments.`,
      i34: `Stores a collection of objects of any type. Other collection types (MULTIPOINT, MULTILINESTRING, MULTIPOLYGON, and GEOMETRYCOLLECTION) restrict collection elements to have specific types of geometry.`,
      i5: `Stores a Boolean value "true" or "false" (true/false).`,
      i9: `Stores data in JSON format.`,
      i27: `The UUID data type ensures uniqueness of identifiers even in distributed systems and avoids conflicts of identifiers when integrating multiple data sources or working with parallel processes. Note that MySQL does not have a built-in UUID data type (unlike, for example, PostgreSQL and MongoDB), but a UUID can be generated on the backend and stored as VARCHAR or CHAR.`,
    },

    DATA_TYPES_GROUPS: {
      NUMBER: 'Numeric',
      DECIMAL: 'Decimal',
      TEXT: 'Text',
      BINARY: 'Binary',
      DATETIME: 'Date/Time',
      DATETIME_TIP: `Data types that allow for working with dates and times.`,
      CUSTOM: 'Composite',
      CUSTOM_TIP: '`String data types that can store values only from a pre-defined list. Even though the list of values consists of strings, only numbers associated with the possible values are stored in the tables. Therefore, they take up little space.`',
      SPATIAL: 'Geometric',
      OTHER: 'Other',
    }
  },

  toggleMouse: {
    mouse: 'Select if you are using a mouse instead of a touchpad',
    touchpad: 'Select if you are using a touchpad instead of a mouse',
  },

  header: {
    USER_MENU: {
      out: 'Log Out',
    },
    toList: 'to schema list',
    helpIcon: 'Brief instructions',
    signin: 'Sign In',
    register: 'Sign Up',
    popup: 'Are you sure you want to delete the project and all its tables? This action is irreversible!',
  },

  relationSelector: {
    no_selected: "Not selected",
    err: 'getTableNameAndColumns could not find table with ID',
    err_not_found: 'Error: not found',
    del_rel: 'delete relationship',
  },

  shareToEmails: {
    text: 'You have granted access to the project:',
  },

  popupConfirm: {
    text: 'Confirm',
  },

  relationColumnSelector: {
    title: 'Relationship with table:',
    chooseOtherTable: 'choose another',
    reltabsel__title: 'Refer to column:',
  },

  popupShare: {
    title: 'Sharing settings',
    subtitle: 'Who can view this project?',
    url: 'Public link:',
    confirmText: 'Save',
  },

  schemeName: {
    text1: 'save SQL dump',
    t2: 'sharing settings',
    t3: 'delete project',
    alert: 'The name cannot be empty!',
  },

  row: {
    index: 'Index',
    primarykey: 'Primary',
    foreignkey: 'Foreign',
    key: 'key',
    delete_col: 'Delete column',
  },

  toggleTheme: {
    dark: 'Dark Theme',
    light: 'Light Theme',
  },

  radioSelector: {
    text: 'Not selected',
  },

  searchfield: {
    text: 'Search',
  },

  relationTableSelector: {
    text: 'Select table:',
  },

  messageFromRikky: {
    close1: 'remind me later',
    close2: 'I already wrote',
  },

  loading: {
    text: 'Please wait, data is loading',
  },

  selectfield: {
    placeholder: 'Click to select',
    loading: 'Loading...',
  },

  shareEmailRow: {
    title: 'Grant access to someone via e-mail:',
  },

  confirm: {
    title: 'Are you sure?',
    text: 'This action is irreversible!',
  },

  tariffBar: {
    day1: 'days',
    day2: 'day',
    day3: 'day',
    content: 'Your tariff:',
    from: 'of',
  },

  table: {
    add: 'Add Columns',
  },

  tableTitle: {
    change: <Fragment>change the name <br className="mobile" />of the table right here</Fragment>,
    del: "Delete Table",
  },

  buttonBlockRemove: {
    del: 'Remove'
  },

  constants: {
    SHARE_TEXT: [
      "Only me",
      "Everyone",
      "Enter E-mail",
    ],
    SHARE_ACCESS_LIST: {
      reader: 'reader',
      editor: 'editor',
    }
  },

  foreignOnActions: {
    tip1: 'Automatically deletes or modifies rows from the dependent table when deleting or modifying related rows in the main table.',
    tip2: 'When deleting or updating a related row from the main table, sets the foreign key column to NULL',
    tip3: 'Rejects the deletion or modification of rows in the main table if there are related rows in the dependent table.',
    tip4: 'When deleting a related row from the main table, sets the foreign key column to a default value defined using the DEFAULT attribute. Note: despite this option being available, the InnoDB engine does not support this expression.',
  },

  verifyCodeField: {
    title: 'Enter confirmation code',
    subtitle: 'A letter with the code has been sent to your e-mail',
  },

  resendController: {
    request: <Fragment>you can request a resend <br />through</Fragment>,
    second: 'seconds',
    repeat: 'resend code',
    limit: 'you have exceeded the limit for sending codes',
    text1: <Fragment>if the code doesn\'t arrive in your email, please inform<br />about this in the chat on the main page of the website<br /></Fragment>,
    text2: 'specifying your email',
  },

  form: {
    alert: 'The name cannot be empty!',
  },
}