import cssIf from "../../../scripts/helpers/class.add.if";
import "./radio.selector.scss";
import {LOCALE, WORDBOOK} from "../../../locales";
import useGlobal from "../../../store";

const RadioSelector = ( props ) => {

  const [ globalState ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].radioSelector;

  const {

    list = [],
    selected = null,
    select = () => {},
    withNotChoosenVariant = false,
    className = "",

  } = props;

  return (

    <div className = {`radio ${ className }`}>

      { withNotChoosenVariant && 
      
        <RadioElement 
        
          id = { false } 
          name = { t.text }
          action = { select } 
          isSelected = { !selected && selected !== 0 } 

        /> 
          
      }

      { list.length > 0 && list.map(( el, key ) => {

        let id = key;
        let name = el;

        if ( el?.id ) {

          id = el.id;
          name = el?.name || el?.text || el.id;

        }

        return (

          <RadioElement 
                    
            id = { id } 
            name = { name }
            action = { select } 
            isSelected = { +id === +selected } 

          />
          
        );

      })}

    </div>

  );

}

export const RadioElement = ({ id, name, isSelected = false, action }) => (

  <div 
  
    onClick = { () => action( id ) }
    className = {`radio__element ${ cssIf( isSelected, 'radio__element--selected' ) } flex items-center pointer`}
    
  >

    <div className = "radio__element__anchor relative">
      <div />
    </div>

    { name }

  </div>

)

export default RadioSelector;