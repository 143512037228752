import { useEffect, useState } from "react";
import { ReactComponent as Dark } from "./icons/moon.svg";
import { ReactComponent as Light } from "./icons/sun.svg";
import cssIf from "../../../scripts/helpers/class.add.if";
import { THEME } from "../../../settings/constants";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";
import "./toggle.theme.scss";

const ToggleTheme = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { theme, locale } = globalState;
  const { changeStates } = globalActions;

  const t = WORDBOOK[ locale ].toggleTheme;
  
  const action = v => {
    
    localStorage.setItem('theme', v );
    changeStates({ theme: v });
  
  }

  const elementWidth = 100 / 2;
  const [ anchorOffset, setAnchorOffset ] = useState( 0 );

  useEffect(() => { 
    
    setAnchorOffset( ( 100 / 2 ) * ( theme - 1 )); 
    document.body.style.background = theme === THEME.LIGHT ? "#fff" : "#1E2C39";
  
  }, [ theme ]);
  
  return (

    <div className = "toggletwo toggletheme">

      <div className = {`toggletwo__container relative`}>

        <div className = {`toggletwo__list absolute flex`}>

          <div
            onClick = { () => { action( THEME.DARK ) } }
            style = {{ width: `${ elementWidth }%` }}
            className={`toggletwo__list__element toggletwo__list__element--dark ${ cssIf( theme === THEME.DARK, `toggletwo__list__element--selected` ) } flex justify-center items-center`}
            title = { t.dark }
          >
            <Dark />
          </div>

          <div
            onClick = { () => { action( THEME.LIGHT ) } }
            style = {{ width: `${ elementWidth }%` }}
            className={`toggletwo__list__element toggletwo__list__element--light ${ cssIf( theme === THEME.LIGHT, `toggletwo__list__element--selected` ) } flex justify-center items-center`}
            title ={ t.light }
          >
            <Light />
          </div>

        </div>

        <div

          className = {`toggletwo__anchor absolute`}
          style = {{

            left: `${ anchorOffset }%`,
            width: `${ elementWidth }%`

          }}

        />
        
      </div>
     
    </div>

  );

}

export default ToggleTheme;