import GlobalHook from "use-global-hook";
import * as actions from "./actions";
import { SHARE, SHARE_ACCESS, THEME } from "../settings/constants";
import { CURSOR } from "../settings/user.mouse.settings";
import { LOCALE } from "../locales";
import cookie from "react-cookies";

export const initialPopupStates = {

  popup_opened:           false,
  popup_animate_close:    false,
  popup_confirm_title:    null,
  popup_confirm_text:     null,
  popup_confirm_icon:     null,
  popup_confirm_function: null,

}

const initialState = {

  dummy: "",
  theme: +localStorage.getItem('theme') || THEME.DARK,
  locale: cookie.load('lang') || LOCALE.RU,

  ...initialPopupStates,
  
  user_name: "",
  user_tariff: {
    active: false,
    comment: "",
    duration: "",
    id: "",
    name: "",
    next_date: "",
    remaining: "",
  },
  user_controller: +localStorage.getItem('mouse') || CURSOR.MOUSE,
  showHelpPopup: false,
  disableOpenedHelp: false,
  isTablesOnTopLayer: false,

  zoom: 100,
  schemes: [],
  schemes_shared: [],

  scheme_id: "",
  scheme_access: SHARE_ACCESS.READ,
  scheme_name: "",
  scheme_description: "",
  scheme_shared_mode: SHARE.ONLY_ME,
  scheme_shared_emails: [],

  scheme_color_groups: [{
    id: 1,
    color: '#34A400',
    name: 'Без группы'
  }],

  scheme_color_groups_visible: [],

  tables: []

};

const useGlobal = GlobalHook( initialState, actions );

export default useGlobal;
