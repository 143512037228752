import { Fragment } from "react";
import { TableInList } from "../relation.table.selector";
import RadioSelector from "../../../../radio.selector";
import { WORDBOOK } from "../../../../../../locales";
import useGlobal from "../../../../../../store";
import "./relation.column.selector.scss";

const RelationColumnSelector = ( props ) => {

  const [ globalState ] = useGlobal();

  const t = WORDBOOK[ globalState.locale ].relationColumnSelector;

  const {
    
    columns,
    tableName,
    select,
    selected,
    chooseOtherTable

  } = props;

  return (

    <div className = "reltabsel relcolsel">

      <div className = "reltabsel__title">{ t.title }</div>

      <TableInList

        name = {(
        
          <Fragment>
            
            { tableName } 
            <span
              onClick = { chooseOtherTable }
              className = "relcolsel__choose_other_table relative pointer"
            >{ t.chooseOtherTable }</span>
            
          </Fragment>
          
        )}

      />

      <div className = "reltabsel__title">{ t.reltabsel__title}</div>


      <div className = "table__form__liner_x" />

      <div className = "reltabsel__list">

        <RadioSelector

          list = { columns }
          selected = { selected }
          select = { select }

        />

      </div>

    </div>

  );

}

export default RelationColumnSelector;