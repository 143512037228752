import React from 'react';
import useGlobal from '../../../store';
import { WORDBOOK } from "../../../locales";
import './tariff.bar.scss';

const TariffBar = ({ data }) => {

  const [ globalState, globalActions ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].tariffBar;

  let { name, duration, remaining } = data;

  let current = duration - remaining;
  if ( current < 0 ) current = 0;

  const percent = current / duration * 100;
  const daytext = ( current >= 5 || current === 0 ) ? t.day1 : current > 1 ? t.day2 : t.day3;
  // const daytext = "день";

  return (

    <div className="tariffbar flex justify-between items-center">

      <div className="tariffbar__content tariffbar__content--tariffName flex items-center">
        <span className = "desktop">{ t.content }</span> <b>{ name }</b>
      </div>

      <div className="tariffbar__progress">
        <div className="tariffbar__progress__bar" style = {{ width: `${ percent }%` }} />
      </div>

      <div className="tariffbar__content tariffbar__content--remaining flex items-center">
        <b>{ current }</b> <span className='desktop'>{ daytext } { t.from }</span><span className='mobile'>/</span> <b>{ duration }</b>
      </div>
      
    </div>

  );

}

export default TariffBar;