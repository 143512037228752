// import { ReactComponent as SqlIcon } from '../icons/sql.svg';
import { ReactComponent as CopyIcon } from '../icons/copy.svg';
// import { ReactComponent as EyeIcon } from '../icons/eye.svg';

const AdditionalTools = ( props ) => {
  
  const { 

    wordbook,
    className,
    duplicateTable,
  
  } = props;

  return (

    <div className = {`additionalTools ${ className }`}>

      {/* <div className = 'additionalTools__icon additionalTools__icon--sql'>

        <SqlIcon/>

      </div> */}

      <div 
      
        title = { wordbook.dublicateTable }
        className = 'additionalTools__icon additionalTools__icon--copy'
        onClick = { duplicateTable }
        
      >

        <CopyIcon/>

      </div>

      {/* <div className = 'additionalTools__icon additionalTools__icon--eye'>

        <EyeIcon/>

      </div> */}

    </div>

  );

}

export default AdditionalTools;