import { post } from 'axios';
import API from '../../settings/api'
import cookie from 'react-cookies';

export async function setLang( store, lang, saveToDB = true, reloadDummy = () => {} ) {
  
  try {

    store.setState({ locale: lang });

    cookie.save('lang', lang, {

      path: '/',
      domain: '.database-design.ru',
      maxAge: 60 * 60 * 24 * 365

    });

    reloadDummy();

    const token = cookie.load('token');
    ( token && saveToDB ) && await post( API.user.setLang, { token: token, lang } );
    
  } catch ( err ) {

    return false;    

  }
  
}