import { useState } from 'react';
import Searchfield from '../../../../textfield/searchfield';
import ColorRow from '../color.row';
import s from "./color.search.module.scss";
import cssIf from '../../../../../../scripts/helpers/class.add.if';
import { THEME } from '../../../../../../settings/constants';

const ColorSearch = ( props ) => {

  const { 
    
    wordbook,
    groups, 
    selected, 
    onSelect, 
    onAdd, 
    onEdit,
    onMouseEnter,
    theme

  } = props;

  const t = wordbook;

  const [ search, setSearch ] = useState("");

  return (

    <div 
    
      className = {` ${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `}
      onMouseEnter = { onMouseEnter }
      
    >

      <div className = { s.title }>{ t.chooseTableColor }</div>

      <div className = { s.line }/>

      <Searchfield

        className = { s.search }
        value = { search }
        set = { setSearch }

      />

      <div className = { s.rows }>

        <ColorRow isNew onNewClick = { onAdd } theme = { theme }/>

        { groups.filter( el => el.name.toLowerCase().includes( search.toLowerCase() ) ).map( ( item, key ) => (

          <ColorRow 
          
            withIcon
            key = { key }
            id = { item.id }
            name = { item?.name !== '' ? item.name : t.colorGroupNameEmpty } 
            color = { item.color } 
            isChosen = { item.id === selected }
            onRowClick = { () => onSelect( item.id ) }
            onPenClick = { onEdit }
            theme = { theme }
          
          />

        ))}

      </div>

    </div>

  );

}

export default ColorSearch;