import React, { useState, useRef, useEffect, useMemo } from 'react';
import cookie from 'react-cookies';
import useXarrow from '../dynamic.arrows/useXarrow';
import { RandomHash } from '../../../scripts/hooks/use.reload.dummy';
import Draggable from 'react-draggable';
import { arrayMove } from 'react-sortable-hoc';
import SortableRows from './rows';
import Form from './form';
import smartWidth from '../../../scripts/helpers/smart.input.width';
import cssIf from '../../../scripts/helpers/class.add.if';
import { apx1 } from '../../../scripts/helpers/apx';
import { DATA_TYPES } from '../../../settings/data.types';

import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as AnchorIcon } from './icons/anchor.svg';
import { ReactComponent as ConfirmIcon } from './icons/confirm.svg';
import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as ArrowDownIcon } from './icons/arrow.down.svg';

import { WORDBOOK } from "../../../locales";
import ColorAndDescription from './color.and.description';
import useGlobal from '../../../store';
import AdditionalTools from './additional.tools';
import { THEME } from '../../../settings/constants';
import './table.scss';

const Table = ( props ) => {

  const {

    id, 
    groupID,
    assignColorGroup,
    position, 
    name, 
    tip = "",
    columns, 
    color = "", 
    canEdit = true,
    renameTable, 
    duplicateTable, 
    removeTable, 
    addColumn, 
    editColumn, 
    removeColumn, 
    updateTablePosition,
    updateRowsPosition,
    setDisablePinchZoom,
    setTablesOnTopLayer,
    tablesLength = 0

  } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { scheme_color_groups_visible, locale } = globalState;

  const wordbook = WORDBOOK[ locale ];
  const t = wordbook.table;

  const isTableColorGroupVisible = (

    !scheme_color_groups_visible.length
    ||
    scheme_color_groups_visible.includes( groupID )

  );

  const closeRef = useRef( null );
  const thisTable = useRef( null );

  const [ showForm, setShowForm ] = useState( false );
  const [ showComments, setShowComments ] = useState( false );

  const [ editData, setEditData ] = useState({ });
  const [ newRandomIfColumnEdited, setNewRandomIfColumnEdited ] = useState(0);

  const [ isEditingTableHead, setIsEditingTableHead ] = useState( false );

  const updateXarrow = useXarrow();

  function openEditForm( data ) {

    console.log( `openEditForm`, data );

    setShowForm( false );
    setEditData({});
    setEditData( data );
    setShowForm( true );
    setTablesOnTopLayer( true );
    
  }

  const closeEditForm = () => { setEditData({}); setShowForm( false ); setTablesOnTopLayer( false ); }

  function makeColumn( data ) {

    if ( editData?.id ) { //edit column

      editColumn( id, editData.id, data );
      setEditData({});

    } else { //add column

      addColumn( id, data );

    }

    setNewRandomIfColumnEdited( RandomHash() );

  }
 
  function stopDragging( e ) {

    const tableDOM = document.getElementById(`table-${ id }`);
    if ( !tableDOM ) { return; }

    const pos = tableDOM.style.transform.toString().substring(0, tableDOM.style.transform.toString().length - 1 ).substring( 10 );
    
    const position = {
      x: pos.split(',')[ 0 ].replace( "px", "" ),
      y: pos.split(',')[ 1 ].substring( 1 ).replace( "px", "" )
    }

    console.log(`New position table [ ${ name } ]:`, position );

    updateXarrow( e );
    updateTablePosition( id, position );

  }

  function handleMoveRow({ oldIndex, newIndex }) {
    
    const updated = arrayMove( columns, oldIndex, newIndex > 0 ? newIndex : 1 );
    updateRowsPosition( id, updated );
    setDisablePinchZoom( false );

  }

  function handleFormControlBtn() {

    if ( !showForm  ) {

      setShowForm( true ) 
      setTablesOnTopLayer( true )
    
    } else {
      
      closeRef.current()
      setDisablePinchZoom( false );
    
    }

  }

  const typeColumnWidth = useMemo(() => {

    const largestTypeName = columns.map( col => DATA_TYPES?.find( t => +t.id === +col.type )?.name ).sort( (a, b) => b.length - a.length )[ 0 ];
    const result = largestTypeName?.length > 6 ? `${( ( apx1 * 19 ) * largestTypeName.length ).toFixed( 4 ) }vw` : `${ ( apx1 * 125 ).toFixed( 4 ) }vw`;
    //// console.log(`table-${ id } recalculated typeColumnWidth:`, result );
    return result;

  }, [ columns?.length, newRandomIfColumnEdited ]); 

  return (

    <Draggable

      scale = { props.areaScale * 0.2 }
      handle = ".dragTable"
      disabled = { !canEdit }
      onStart = { () => { setDisablePinchZoom( true ) }}
      onDrag = { updateXarrow }
      onStop = { ( e ) => { stopDragging( e ); setDisablePinchZoom( false ); } }
      defaultPosition = {{
        
        x: parseInt( position.x ), 
        y: parseInt( position.y )

      }}

    >

      <div 
        id = {`table-${ id }`}
        ref = { thisTable }
        style = { !isTableColorGroupVisible ? { opacity: 0 } : {} }
        className = {`table ${ cssIf( showForm, 'table--form-is-opened' ) }`}
        onMouseEnter = { () => setTablesOnTopLayer( true ) }
        onMouseLeave = { () => !showForm && setTablesOnTopLayer( false ) }
      >

        {/* <div className="table__debug">{`table-${ id }`}</div> */}

        <div 
        
          className="table__tip" 
          style = {{ 
            bottom: `${ ( columns.length * 45 ) + 74 }px`,
            opacity: isEditingTableHead ? 0 : 1
          }}
        >
          { tip }
        </div>

        <TableTitle
          
          text = { name }
          tip = { tip }
          groupID = { groupID }
          setGroupID = { ( groupID ) => assignColorGroup( id, groupID ) }
          color = { color }
          canEdit = { canEdit }
          tablesLength = { tablesLength }
          duplicateTable = { () => canEdit && duplicateTable() }
          removeTable = { () => canEdit && removeTable( id ) }
          setDisablePinchZoom = { setDisablePinchZoom }

          showInput = { isEditingTableHead }
          setShowInput = { setIsEditingTableHead }

          set = { ( d ) => { canEdit && 
            
            renameTable({ 

              table_id: id,
              name: d.name,
              tip: d.tip

            })
          
          }}

        />        

        <div className="table__rows relative">
          
          { columns.length > 0 
          
            ? <SortableRows 
            
                table_id = { id }
                canEdit = { canEdit }
                data = { columns }
                typeColumnWidth = { typeColumnWidth }

                showComments = { showComments }
                openEditForm = { openEditForm }
                removeColumn = { ( tableID, columnID ) => {
                  
                  ( +editData?.id === +columnID ) && closeEditForm();
                  removeColumn( tableID, columnID );
                
                }}

                axis = "y"
                lockAxis = "y"
                useDragHandle = { true }
                lockToContainerEdges = { true }
                helperClass = "table__row--flying"
                onSortStart = { () => { 
                  
                  setDisablePinchZoom( true );
                  updateXarrow();
                  
                  const thisTable = document.getElementById(`table-${ id }`);
                  const currentScale = +cookie.load('dbd-scale');

                  if ( thisTable ) { 

                    let newScale = 1.0;
                    if ( currentScale < 1 ) { newScale = 1.0 + currentScale; }
                    thisTable.style.transform = `translate(${ position.x }px, ${ position.y }px) scale(${ newScale })`;

                  }
                                  
                }}
                onSortMove = { updateXarrow }
                onSortEnd = { ( attrs ) => { 

                  handleMoveRow( attrs );                  

                  const thisTable = document.getElementById(`table-${ id }`);

                  if ( thisTable ) { 
                    thisTable.style.transform = `translate(${ position.x }px, ${ position.y }px)`;
                  }

                  updateXarrow();

                }}
                
              />

            : <div className="table__row table__row_empty"><span>{ t.add }</span></div>
          
          }
          
        </div>

        {( canEdit || showForm ) &&
          
          <div 

            onClick = { handleFormControlBtn }
            className = {`table__add ${ cssIf( columns.length % 2 !== 0, "table__add--light" ) }`} 

          >
            <AnchorIcon className = "anchorIcon" />

          </div>
        
        }
      
        <Form

          { ...editData }
          isOpened = { showForm }
          canEdit = { canEdit }
          action = { makeColumn }
          close = { closeEditForm } 
          setDisablePinchZoom = { setDisablePinchZoom }
          thisTable = { thisTable.current }
          closeRef = { closeRef }

        />           
      
      </div>

    </Draggable>

  );

}

const TableTitle = ( props ) => {

  const {

    groupID,
    setGroupID,

    text, 
    tip = '',
    color,

    set, 
    removeTable, 
    duplicateTable, 
    setDisablePinchZoom, 
    canEdit = true, 
    tablesLength,

    showInput,
    setShowInput

  } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { scheme_color_groups, theme, locale } = globalState;

  const wordbook = WORDBOOK[ locale ];
  const t = wordbook.tableTitle;

  const group = scheme_color_groups.find( el => el.id === groupID );

  const inputRef = useRef( null );
  const [ title, setTitle ] = useState( text );
  const [ background, setBackground ] = useState( color );
  const [ titleWidth, setTitleWidth ] = useState( ( text.length * 16 ) * apx1 );
  const [ description, setDescription ] = useState( tip );
  const [ isEditingColor, setIsEditingColor ] = useState( false );
  const [ isAdditionalShown, setIsAdditionalShown ] = useState( false );

  const handleSet = () => set({ name: title, tip: description });

  function startRenaming() {

    setShowInput( true );
    setTimeout(() => { inputRef.current.focus(); }, 200 );

  }

  function handleKeyUp( e ) {
    
    if ( e.key === 'Enter' || e.key === 'Escape' ) {

      setShowInput( false );
      e.key === 'Enter' && handleSet();
      setDisablePinchZoom( false );

    }

  }

  useEffect(() => { showInput && setTitleWidth( smartWidth( inputRef.current.scrollWidth, title, 16 ) * apx1 ); }, [ showInput, title ])

  useEffect(() => { setTitle( text ) }, [ text ]);
  useEffect(() => { setDescription( tip ) }, [ tip ]);
  useEffect(() => { setBackground( color ) }, [ color ]);

  return (

    <div 

      className = {`table__title ${ cssIf( showInput, 'title--top-is-opened' ) }`}
      style = {{ 
        
        backgroundColor: isEditingColor ? background : ( group?.color || background )

      }}

    >

      <div onMouseEnter = { () => setIsAdditionalShown( true ) } onMouseLeave = { () => setIsAdditionalShown( false ) }>

        <TableIcon className = {`dragTable ${ cssIf( !canEdit, "events-none" ) }`}/>

        { canEdit && 
        
          <AdditionalTools 
          
            wordbook = { wordbook.constructor }
            className = {` ${ cssIf( isAdditionalShown, "additionalTools--shown" ) } ${ cssIf( theme === THEME.LIGHT, "additionalTools--light" ) } `} 
            duplicateTable = { duplicateTable }
            
          />
          
        }

      </div>

      { !showInput
      
          ? <span onClick = { () => ( !showInput && canEdit ) && startRenaming() } >{ title }</span>

          : <React.Fragment>
            
              <input 

                ref = { inputRef }
                type = "text" 
                value = { title } 
                onChange = { e => setTitle( e.target.value ) }
                onKeyUp = { handleKeyUp }
                style = {{ width: `${ titleWidth }vw` }}

              />

              { tablesLength <= 3 && 
              
                <div className = "tip_rename flex justify-center items-center absolute">

                  { t.change }

                  <ArrowDownIcon className = "tip_rename__arrow relative" />

                </div>
                
              }

              { !isEditingColor && 
              
                <ConfirmIcon 

                  className = "saveNameAndColor"

                  onClick = {() => {

                    if ( !canEdit ) { return; }

                    setShowInput( false );
                    setDisablePinchZoom( false );
                    handleSet();
                    
                  }}

                />
                
              }

            </React.Fragment>
        
      }

      <ColorAndDescription
              
        wordbook = { wordbook.colorAndDescription }
        groupID = { groupID }
        setGroupID = { setGroupID }
        color = { background }
        setColor = { setBackground }
        description = { description }
        setDescription = { setDescription }
        className = 'table__top'
        isShown = { showInput }
        setDisablePinchZoom = { setDisablePinchZoom }
        setIsEditingColor = { setIsEditingColor }

      />

      { ( !showInput && canEdit ) &&

        <TrashIcon 

          title = { t.del }
          className = "removeTable"
          onClick = { removeTable }          

        />

      }

    </div>

  );

}

export default Table;