import { WORDBOOK } from '../../../../../../locales';
import { ReactComponent as PenIcon } from '../../../icons/pen.svg';
import { ReactComponent as AddNewGroupIcon } from '../../../icons/add.new.group.svg';
import cssIf from '../../../../../../scripts/helpers/class.add.if';
import { THEME } from '../../../../../../settings/constants';
import s from "./color.row.module.scss";
import useGlobal from '../../../../../../store';

const ColorRow = (props) => {

  const {

    id = false,
    color, 
    name, 
    withIcon = false, 
    isChosen = false, 
    isNew = false, 
    isAll = false,
    className = '',
    onRowClick,
    onPenClick,
    onNewClick,
    theme
    
  } = props;

  const [ globalState ] = useGlobal();

  const t = WORDBOOK[ globalState.locale ].colorAndDescription;

  const isNotEditable = !id || id === 1;

  return (

    <div 
    
      className = {` ${ s.container } ${ className } ${ cssIf( isChosen, s['container--choosen']) } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `} 
      onClick = { isNew ? onNewClick : withIcon ? () => onRowClick(color) : onRowClick }
    
    >

      { isNew || isAll
      
        ? <AddNewGroupIcon className = { s.new }/>
        : <div className = { s.color } style = {{ backgroundColor: `${ color }` }}/>
      
      }

      { isNew
      
        ? <p className = { s.name }>{ t.addNewGroup }</p>
        : isAll 
          ? <p className = { s.name }>{ t.showAllTables }</p>
          : <p className = { s.name } style = { isChosen ? { color } : {} }>{ name }</p>

      }

      {( withIcon && isChosen && !isNotEditable ) &&
      
        <PenIcon 

          className = { s.pen } 
          title = { t.editColorGroupTip }
          style = { isChosen ? { stroke: color } : { stroke: 'rgb(140, 180, 215)' }}
          onClick = { (e) => { 

            onPenClick(); 
            e.preventDefault(); 
            e.stopPropagation(); 
          
          }}

        /> 
        
      }

    </div>

  );

}

export default ColorRow;