import ShareEmailRow from "../../components/share.email.row";
import { WORDBOOK } from "../../../locales";
import useGlobal from "../../../store";


const ShareToEmails = ({ users = [], add, remove, editAccess }) => {

  const [ globalState ] = useGlobal();
  const t = WORDBOOK[ globalState.locale ].shareToEmails;

  return (

    <div>

      <ShareEmailRow action = { add } />

      { users.length > 0 && 
      
        <>
        
          <br />

          { t.text }
          
          { users.map(( user, key ) => (

            <ShareEmailRow

              key    = { key }
              id     = { user.id }
              email  = { user.email }
              access = { user.access }
              action = { remove }
              changeAccessAction = { editAccess }
            
            />

          ))}

        </>

      }
      
    </div>

  );

}

export default ShareToEmails;