import ColorRow from '../../table/color.and.description/color/color.row';
import cssIf from '../../../../scripts/helpers/class.add.if';
import { THEME } from '../../../../settings/constants';
import { WORDBOOK } from '../../../../locales';
import useGlobal from '../../../../store';
import Checkbox from "../../checkbox";
import s from "./groups.module.scss";

const ToolGroups = ( props ) => {

  const { theme } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { scheme_color_groups, scheme_color_groups_visible, locale } = globalState;
  const { tableColorGroup } = globalActions;

  const t = WORDBOOK[ locale ].sidePanelTools;

  const isShowAllColorGroups = scheme_color_groups_visible.length === 0;

  return (

    <div className = {` ${ s.container } ${ cssIf( theme === THEME.LIGHT, s.containerLight ) } `}>

      <div className = { s.title }>{ t.showTableGroups }</div>

      <div className = { s.line }/>

      <div className = { s.rows }>

        <div 
        
          className = { s.row }
          onClick = { () => !isShowAllColorGroups && tableColorGroup.toggleView('all') }
        
        >

          <Checkbox value = { isShowAllColorGroups } className = { s.checkbox }/>
          <ColorRow isAll theme = { theme } className = { s.colorRow }/>

        </div>

        { scheme_color_groups.map( ( item, key ) => (

          <div 
          
            key = { key }
            className = { s.row }
            onClick = { () => tableColorGroup.toggleView( item.id ) }
          
          >

            <Checkbox 
            
              value = { scheme_color_groups_visible.includes( item.id ) } 
              className = { s.checkbox }
              
            />

            <ColorRow 
            
              key = { key }
              id = { item.id }
              name = { item?.name !== '' ? item.name : t.colorGroupNameEmpty } 
              color = { item.color } 
              theme = { theme }
              className = { s.colorRow }
            
            />

          </div>

        ))}

      </div>

    </div>

  );

}

export default ToolGroups;